import * as React from 'react'
import { AsyncErrorHandler } from '../../../lib/async-error-handler'
import { cartPath } from '../lib/cart'

interface IProps {
  onClick: (event: any) => Promise<any>
  className?: string
  productName?: string
  addedStatusDelay?: number
  cartPath?: string
  errorHandler?: AsyncErrorHandler

  canAddToCart: boolean
}

enum Status {
  Default = 'DEFAULT',
  Adding = 'ADDING',
  Added = 'ADDED',
  GoToCart = 'GO_TO_CART',
}

interface IState {
  status: Status

  error?: null
}

// Show Added status text for this interval in milliseconds
const defaultAddedStatusDelay: number = 1500

export class AddToCartButton extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps> = {
    addedStatusDelay: defaultAddedStatusDelay,
    cartPath: cartPath(),
  }

  constructor(props: IProps) {
    super(props)

    const errorHandler = props.errorHandler || new AsyncErrorHandler(this)

    this.handleClick = errorHandler.wrap(this, this.handleClick)
    this.handleAddedStatusTimeout = this.handleAddedStatusTimeout.bind(this)

    this.state = {
      status: Status.Default,
    }
  }

  public render() {
    const {
      children,
      className,
      productName,
      canAddToCart,
    } = this.props

    const statusMessage = this.statusMessage()

    return (
      <button
        data-track-event="true"
        data-event-category="ShoppingCart"
        data-event-action={this.state.status}
        data-event-label={productName}
        onClick={this.handleClick}
        className={className}
        id={productName}
        {...(!canAddToCart && {
          'disabled': true,
          'data-tooltip': 'Workbooks are only available to authorized churches.',
          'data-placement': 'top',
        })}
      >
        {statusMessage ? statusMessage : children }
      </button>
    )
  }

  private statusMessage() {
    const { status, error } = this.state
    if (error) {
      return 'Error!'
    }

    switch (status) {
      case Status.Adding:
        return 'Please wait...'
      case Status.Added:
        return 'Added!'
      case Status.GoToCart:
        return 'Go to Cart >'
      default:
        return null
    }
  }

  private async handleClick(event) {
    const {
      onClick,
      canAddToCart,
    } = this.props
    const {
      status,
    } = this.state

    event.preventDefault()
    if (!canAddToCart) {
      return false
    }

    switch (status) {
      case Status.Default: {
        this.setState({ status: Status.Adding })
        const result = await onClick(event)
        this.handleAddedToCart()
        return result
      }
      case Status.GoToCart:
        return this.redirectToCart()
    }
  }

  private handleAddedToCart(): void {
    const {
      addedStatusDelay,
    } = this.props

    this.setState({ status: Status.Added })
    setTimeout(this.handleAddedStatusTimeout, addedStatusDelay)
  }

  private handleAddedStatusTimeout(): void {
    this.setState({ status: Status.GoToCart })
  }

  private redirectToCart() {
    window.location.href = this.props.cartPath
  }
}
