import * as React from 'react'
import * as Client from './shopify_client'

interface IAddVariantToCartArgs {
  checkoutID: string
  buildCheckoutAttributes: Client.IBuildCheckoutAttributes
  customAttributes: Client.IMappedCustomAttributes
  variantID: string
  quantity: number
}

interface IProps {
  checkoutID?: string
  buildCheckoutAttributes?: Client.IBuildCheckoutAttributes
  sourceURL?: string
  ministryName?: string
  addVariantToCart: (args: IAddVariantToCartArgs) => Promise<Client.ICheckout>
  [propName: string]: any
}

interface IState {
  checkout?: Client.ICheckout
}

interface ICheckoutProps {
  addVariantToCart?: (params: Partial<IAddVariantToCartArgs>) => Promise<Client.ICheckout>,
}

function withCheckout<TProps extends ICheckoutProps, TState>(
  WrappedComponent: new (...args: any[]) => React.Component<TProps, TState>,
) {
  return class extends React.Component<IProps & TProps, IState> {
    public static defaultProps: Partial<IProps> = {
      addVariantToCart: Client.addVariantToCart,
    }

    public constructor(props) {
      super(props)

      this.state = {}
    }

    public render() {
      const {
        checkoutID,
        buildCheckoutAttributes,
        addVariantToCart,
        sourceURL,
        ministryName,
      } = this.props
      const {
        checkout: storedCheckout,
      } = this.state

      const storeCheckout = (newCheckout: Client.ICheckout) => {
        this.setState({ checkout: newCheckout })
      }

      const passedProps = Object.assign({}, this.props, {
        addVariantToCart: async ({ variantID, quantity }: Partial<IAddVariantToCartArgs>) => {
          const newCheckout = await addVariantToCart({
            checkoutID: storedCheckout ? storedCheckout.id : checkoutID,
            buildCheckoutAttributes,
            variantID,
            quantity,
            customAttributes: {
              _sourceURL: sourceURL,
              _ministryName: ministryName,
              _accountID: buildCheckoutAttributes.accountID.toString(),
            },
          })

          storeCheckout(newCheckout)

          return newCheckout
        },
      })

      return <WrappedComponent {...passedProps}/>
    }
  }
}

export { withCheckout }
