import * as React from 'react'
import { AsyncErrorHandler } from '../../../lib/async-error-handler'

interface IProps {
  author: {
    id: string,
    fullName: string,
  }

  queryApiUrl: string
}

interface IState {
  loadedAuthor?: IRemoteAuthor

  error?: any,
  wait?: boolean
}

interface IRemoteAuthor {
  firstName?: string
  lastName?: string
  title?: string
  shortBio?: {
    html: string,
  },
  profileImage?: {
    title: string
    description: string
    file: {
      url: string,
    },
  },
}

export class BlogAuthor extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps> = {
    queryApiUrl: 'https://www.watermark.org/api/v1/graphql',
  }
  private readonly errorHandler = new AsyncErrorHandler(this)

  constructor(props: IProps, context?: any) {
    super(props, context)
    this.state = {}

    this.loadAuthorData = this.errorHandler.wrap(this, this.loadAuthorData)
  }

  public render() {
    const {author} = this.props
    const {loadedAuthor} = this.state || ({} as IState)

    const name = loadedAuthor ?
      [loadedAuthor.firstName, loadedAuthor.lastName].filter((n) => n).join(' ') :
      author.fullName

    return <>
        <h5 className="blog-post-author__header">About the Author</h5>
        <div className="blog-post-author__row">
          <div className="col-12 col-md-3">
            <div className="embed-responsive embed-responsive-1by1">
              {loadedAuthor &&
                <img className="embed-responsive-item blog-post-author__image"
                  src={loadedAuthor.profileImage &&
                    loadedAuthor.profileImage.file &&
                    loadedAuthor.profileImage.file.url}
                  alt={loadedAuthor.profileImage &&
                    (loadedAuthor.profileImage.description || loadedAuthor.profileImage.title)} />
                }
            </div>
          </div>
          <div className="col-12 col-md-9">
            <div className="">
              <span className="blog-post-author__name">{name}</span><br />
              {loadedAuthor &&
                <span className="blog-post-author__title">{loadedAuthor.title}</span>}
            </div>
            {loadedAuthor &&
              <div className="blog-post-author__bio"
                dangerouslySetInnerHTML={{__html: loadedAuthor.shortBio && loadedAuthor.shortBio.html}}>
              </div>}
          </div>
        </div>
      </>
  }

  public componentDidMount() {
    // tslint:disable-next-line:no-floating-promises background task
    this.loadAuthorData()
  }

  private async loadAuthorData() {
    const { author, queryApiUrl } = this.props

    // TODO: make this a GET that we can cache with a CDN
    const resp = await fetch(queryApiUrl, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        query: `
        query authorOfPost($id: ID!) {
          Person(id:$id) {
            firstName
            lastName
            title
            shortBio {
              html
            }
            profileImage {
              title
              description
              file
            }
          }
        }`,
        variables: { id: author.id },
      }),
    })

    if (resp.status != 200) {
      throw new Error(`Error making graphql query: ${resp.status} ${resp.statusText}`)
    }
    const body = await resp.json()
    if (body.errors && body.errors.length > 0) {
      throw new Error(body.errors.map((e) => e.message || e).join('\n'))
    }

    this.setState({
      loadedAuthor: body.data.Person,
    })
  }
}
