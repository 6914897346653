import * as React from 'react'
import { IResource } from '../../lib/contentful/generated'
import { isAsset, isEntry } from '../../lib/contentful'
import { default as DocumentIcon } from '@material-ui/icons/InsertDriveFile'
import { default as VideoIcon } from '@material-ui/icons/OndemandVideo'
import { default as ImageIcon } from '@material-ui/icons/PhotoOutlined'
import { default as AudioIcon } from '@material-ui/icons/VolumeUpRounded'
import { default as ArticleIcon } from '@material-ui/icons/Description'
import { ResourceActionMenu } from '../resource-action-menu'
import { CopyAssetButton } from '../resource-landing/buttons/copyAssetButton'
import { DownloadAssetButton } from '../resource-landing/buttons/downloadAssetButton'
import { ViewAssetButton } from '../resource-landing/buttons/viewAssetButton'

interface IProps {
  resource: IResource,
  baseUrl?: string
}

const iconMap = {
  article: ArticleIcon,
  asset: ImageIcon,
  audio: AudioIcon,
  document: DocumentIcon,
  image: ImageIcon,
  video: VideoIcon,
}

const LessonResource = ({resource, baseUrl}: IProps) => {
  const { title, icon, viewExternalAssetUrl, viewAsset, viewPage } = resource.fields
  const viewUrl = viewExternalAssetUrl || isAsset(viewAsset) && viewAsset?.fields?.file.url || isEntry(viewPage) && viewPage?.fields?.slug

  const Icon = getIcon(icon)

  return (
    <div className="lesson-resource-set__resource">
      <a href={viewUrl} target="_blank" rel="noopener noreferrer" className="lesson-resource-set__resource-link">
        <div className={`lesson-resource-set__resource-title ${icon}`}>
          {title}
        </div>
        <div className="lesson-resource-set__resource-icon">
          <Icon />
        </div>
      </a>
      <ResourceActionMenu>
        <ViewAssetButton resource={resource} icon={type => {
          switch(type) {
            case 'video':
            case 'audio':
              return <span>Play</span>
            default:
              return <span>View</span>
          }
        }} />
        <DownloadAssetButton resource={resource} icon={<span>Download</span>} baseUrl={baseUrl} />
        <CopyAssetButton resource={resource} icon={(copied) => <span>{copied ? 'Link Copied!' : 'Copy Link'}</span>} baseUrl={baseUrl} />
      </ResourceActionMenu>
    </div>
  )
}

function getIcon(key: string) {
  return iconMap[key] || iconMap['document']
}

export default LessonResource
