import * as React from 'react'
import IonicButton from '../ionic-button'
import MaterialButton from '../material-button'
import { Button } from './button'
import { WCCModalVideo } from './wcc-modal-video'

interface IProps {
  buttonProps?: any
  ionicButton?: boolean
  materialIcon?: JSX.Element
  channel?: string
  videoId?: string
  url?: string
  linkText?: string
  autoplay?: boolean
}

interface IState {
  isOpen: boolean
}

const ionicButtonStyle = {
  display: 'inline',
}

export class ModalVideoPlayer extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      isOpen: false,
    }
    this.openModal = this.openModal.bind(this)
  }

  public openModal(event: any): void {
    event.preventDefault()
    this.setState({ isOpen: true })
  }

  public render() {
    const { channel, videoId, url, autoplay } = this.props
    return (
      <div style={this.renderStyle()}>
        <WCCModalVideo
          channel={channel}
          url={url}
          isOpen={this.state.isOpen}
          youtube={{ autoplay }}
          vimeo={{ autoplay }}
          watermark={{ autoplay }}
          videoId={videoId}
          onClose={() => this.setState({ isOpen: false })}
        />
        {this.renderButton()}
      </div>
    )
  }

  public componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown, false)
  }

  public componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown, false)
  }

  private handleKeyDown = (event) => {
    // ESC or ENTER closes the modal
    if (event.keyCode === 27 || event.keyCode === 13) {
      this.setState({ isOpen: false })
    }
  }

  private renderButton() {
    const buttonProps = this.props.buttonProps || {}
    const linkText = this.props.linkText || 'Watch Video'
    if (this.props.materialIcon) {
      return <MaterialButton { ...buttonProps } onClick={this.openModal}
        icon={this.props.materialIcon} buttonText={linkText} />
    } else if (this.props.ionicButton) {
      return <IonicButton { ...buttonProps } onClick={this.openModal} buttonText={linkText} />
    } else {
      return <Button { ...buttonProps } openModal={this.openModal} linkText={linkText} />
    }
  }

  private renderStyle() {
    if (this.props.ionicButton) {
      return ionicButtonStyle
    }
  }
}
