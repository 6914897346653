import React from "react"
import { default as OpenIcon } from '@material-ui/icons/OpenInNewRounded'
import { default as PlayIcon } from '@material-ui/icons/PlayCircleOutlineOutlined'
import { default as ViewIcon } from '@material-ui/icons/VisibilityOutlined'
import { IResource } from "../../../lib/contentful/generated"
import MaterialButton from "../../material-button"
import { ModalAudioPlayer } from "../../modal-audio-player"
import { ModalImage } from "../../modal-image"
import { ModalVideoPlayer } from "../../modal-video-player"
import { IResourceResult } from "../../resource-search/search_results"
import { isEntry, isAsset } from "../../../lib/contentful"

const youtubeOrVimeoRegex = new RegExp([
  /(http:|https:|)\/\//
  , /(player.|www.)?/
  , /(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\//
  , /(video\/|embed\/|watch\?v=|v\/)?/
  , /([A-Za-z0-9._%-]*)(\&\S+)?/,
].map((r) => r.source).join(''))

const privateVimeoRegex = /vimeo\.com(?:\/video)?\/(?<userid>\w+)(?:\/|\?h\=)(?<videoid>\w+)/

const imageRegex = /(?:jpe?g|png|svg)$/i

// https://support.jwplayer.com/articles/supported-video-and-audio-formats-reference#supported-audio-files
const audioRegex = /\.(?:aac|m4a|f4a|mp3|ogg|oga)$/i

type AssetType = 'image' | 'audio' | 'video' | 'other'

export function ViewAssetButton({ resource, icon }: { resource: IResource | IResourceResult, icon?: ((type: AssetType) => JSX.Element) }) {
  const resourceFields = isEntry(resource) ? resource.fields : resource
  const { title, blurb, viewAsset, viewExternalAssetUrl, viewPage } = resourceFields
  const viewUrl = viewExternalAssetUrl ||
    (isAsset(viewAsset) && viewAsset.fields.file.url ) ||
    (isEntry(viewPage) && viewPage.fields.slug)

  if (!viewUrl) return null

  if (!icon) {
    icon = (type) => {
      switch(type) {
        case 'image':
          return <ViewIcon />
        case 'audio':
        case 'video':
          return <PlayIcon />
        default:
          return <OpenIcon />
      }
    }
  }

  const trackingProps = {
    'data-track-event': true,
    'data-event-category': 'Search',
    'data-event-label': viewUrl,
    'data-event-action': 'View',
  }
  if (audioRegex.test(viewUrl)) {
    return <ModalAudioPlayer
        url={viewUrl}
        name={title}
        album={blurb}

        materialIcon={icon('audio')}
        buttonProps={trackingProps}
      />
  }

  if (privateVimeoRegex.test(viewUrl)) {
    const match = viewUrl.match(privateVimeoRegex)
    const url = `https://player.vimeo.com/video/${match.groups.userid}?h=${match.groups.videoid}&autoplay=true`

    return <ModalVideoPlayer
        channel={'custom'}
        url={url}
        autoplay={true}
        materialIcon={icon('video')}
        buttonProps={trackingProps}
      />
  }

  if (youtubeOrVimeoRegex.test(viewUrl)) {
    const match = viewUrl.match(youtubeOrVimeoRegex)
    const channel = match[3].indexOf('youtu') > -1 ? 'youtube' : 'vimeo'
    const videoId = match[6]

    return <ModalVideoPlayer
        channel={channel}
        videoId={videoId}
        autoplay={true}
        materialIcon={icon('video')}
        buttonProps={trackingProps}
      />

  } else if (imageRegex.test(viewUrl)) {
    return <ModalImage imageUrl={viewUrl}
      buttonProps={trackingProps}
      materialIcon={icon('image')}/>

  } else {
    return (
      <MaterialButton
        buttonText="View"
        href={viewUrl}
        icon={icon('other')}
        target="_blank"
        rel="noopener noreferrer"
        {...trackingProps}
      />
    )
  }
}
