import * as React from 'react'
import MessageRefinementList from './message-refinement-list'

interface IProps {
  attribute: string
  name: string
}

const FacetFilter = (props: IProps) => (
  <div className="dropdown">
    <button className="btn btn-lg btn-block dropdown-toggle rounded-0 text-left"
      type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <span className="current-refinement">{ props.name }</span>
    </button>
    <div className="dropdown-menu dropdown-menu-right rounded-0">
      <div className="option-wrapper">
        <MessageRefinementList
          attribute={ props.attribute }
          transformItems={(items) => items.sort((a, b) => ByLabel(a, b))}
          limit={10000} />
      </div>
    </div>
  </div>
)

const ByLabel = (a, b) => (
  a.label.localeCompare(b.label)
)

export { FacetFilter }
