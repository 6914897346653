import { default as CloseIcon } from '@material-ui/icons/CloseRounded'
import bugsnagClient from 'bugsnag'
import * as React from 'react'
import ReactModal from 'react-modal'

import IonicButton from '../ionic-button'
import MaterialButton from '../material-button'

interface IProps {
  url: string
  /** The name of the song */
  name?: string
  /** Artist/speaker */
  artist?: string
  /** Album title / series title */
  album?: string
  /** Cover art if available */
  cover_art_url?: string

  buttonProps?: any
  ionicButton?: boolean
  materialIcon?: JSX.Element
  linkText?: string
}

interface IState {
  isOpen: boolean
  Amplitude?: any
}

const ionicButtonStyle = {
  display: 'inline',
}

export class ModalAudioPlayer extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      isOpen: false,
    }
    this.openModal = this.openModal.bind(this)
  }

  public openModal(event: any): void {
    event.preventDefault()
    this.setState({ isOpen: true })

    import('amplitudejs')
      .then((Amplitude) => this.initAmplitude(Amplitude))
      .catch((ex) => bugsnagClient.notify(ex))
  }

  public closeModal(event: any) {
    if (event) { event.preventDefault() }

    const {Amplitude} = this.state
    if (Amplitude) {
      Amplitude.pause()
      Amplitude.removeSong(0)
    }
    this.setState({ isOpen: false })
  }

  public render() {
    const { cover_art_url } = this.props
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this
    return (
      <div style={this.renderStyle()}>
        <ReactModal className="modal-audio-player" isOpen={this.state.isOpen} onRequestClose={this.onRequestClose}>
          {/* https://521dimensions.com/open-source/amplitudejs/docs/examples/single-song.html */}
          <div id="single-song-player">
            {cover_art_url && <img alt='' data-amplitude-song-info="cover_art_url"/>}
            <div  className="bottom-container">

              <div  className="control-container">
                <div className="button-container">
                  <div  className="amplitude-play-pause" id="play-pause" />
                </div>
                <div className="info-container">
                  <div  className="meta-container">
                    <CloseIcon className="meta-container-close" onClick={this.onRequestClose} />
                    <span data-amplitude-song-info="name"  className="song-name" />
                    <span data-amplitude-song-info="artist" />
                  </div>
                  <progress  className="amplitude-song-played-progress" id="song-played-progress"
                    onClick={function(evt) { self.progressBarClick(evt, this) }}></progress>

                  <div  className="time-container">
                    <span  className="current-time">
                      <span className="amplitude-current-minutes" />:<span className="amplitude-current-seconds" />
                    </span>
                    <span  className="duration">
                      <span className="amplitude-duration-minutes" />:<span className="amplitude-duration-seconds" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ReactModal>
        {this.renderButton()}
      </div>
    )
  }

  public componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown, false)
  }

  public componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown, false)
  }

  private onRequestClose = (event) => {
    this.closeModal(event)
  }

  private handleKeyDown = (event) => {
    if (!this.state.isOpen) {
      return
    }

    // ESC or ENTER closes the modal
    if (event.keyCode === 27 || event.keyCode === 13) {
      this.closeModal(event)
    }
    // Player controls
    if ([32, 37, 39].includes(event.keyCode)) {
      event.preventDefault()
    }
  }

  private renderButton() {
    const buttonProps = this.props.buttonProps || {}
    const linkText = this.props.linkText || 'Listen'
    if (this.props.materialIcon) {
      return <MaterialButton { ...buttonProps } onClick={this.openModal}
        icon={this.props.materialIcon} buttonText={linkText} />
    } else if (this.props.ionicButton) {
      return <IonicButton { ...buttonProps } onClick={this.openModal} buttonText={linkText} />
    } else {
      return <a { ...buttonProps } className="quote-card__button" href="#" onClick={this.openModal}>
          <i className="ion ion-play" /> {linkText}
        </a>
    }
  }

  private renderStyle() {
    if (this.props.ionicButton) {
      return ionicButtonStyle
    }
  }

  private initAmplitude(Amplitude: any) {
    this.setState( { Amplitude })
    Amplitude.init({
      bindings: {
        37: 'prev',
        39: 'next',
        32: 'play_pause',
      },
      songs: [{
        ...this.props,
      }],
    })
  }

  private progressBarClick(e: React.MouseEvent<HTMLProgressElement>, element: any) {
    const { Amplitude } = this.state
    if (!Amplitude) { return }

    const offset = e.currentTarget.getBoundingClientRect()
    const x = e.pageX - offset.left as any

    Amplitude.setSongPlayedPercentage( ( parseFloat( x ) / parseFloat( e.currentTarget.offsetWidth as any ) ) * 100 )
  }
}
