import * as React from 'react'
import { LessonMenu, ILessonMenu } from './lesson-menu'

export const LessonSets = ({ sets }: { sets: ILessonMenu[]}) => {
	const menus = sets.map((set, index) => {
		return <LessonMenu key={index} set={set.set} menuName={set.menuName} />
	})

	return (
		<div className="section-resource-landing__lesson-sets">
			{menus}
		</div>
	)
}
