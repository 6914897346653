import { createCheckout } from './create_checkout'
import { IBuildCheckoutAttributes, ICheckout } from './types'

export async function ensureCheckoutID(
  { checkoutID, buildCheckoutAttributes }:
    {
      checkoutID: string,
      buildCheckoutAttributes: IBuildCheckoutAttributes,
    },
): Promise<string> {
  if (checkoutID) {
    return checkoutID
  }

  const checkout: ICheckout = await createCheckout(buildCheckoutAttributes)

  return checkout.id
}
