import * as path from 'path'
import * as React from 'react'
import { Can, connectAbility } from '../../lib/connectors/ability'
import { Asset, IAsset, ILink, isAsset, isEntry, Resolved } from '../../lib/contentful'
import {
  IPage,
  IResource,
  IResourceFields,
  Resource as ResourceClass,
  ResourceDomainObject,
  ResourceIcon,
  ResourceSecurityLevel,
} from '../../lib/contentful/generated'
import MaterialButton from '../material-button'
import { ModalImage } from '../modal-image'
import { ModalVideoPlayer } from '../modal-video-player'

// Action icons
import { default as DownloadIcon } from '@material-ui/icons/GetApp'
import { default as PlayIcon } from '@material-ui/icons/PlayCircleOutlineOutlined'
import { default as ViewIcon } from '@material-ui/icons/VisibilityOutlined'

// Resource type icons
import { default as DocumentIcon } from '@material-ui/icons/InsertDriveFileOutlined'
import { default as VideoIcon } from '@material-ui/icons/LocalMoviesRounded'
import { default as ImageIcon } from '@material-ui/icons/PhotoOutlined'
import { default as AudioIcon } from '@material-ui/icons/VolumeUpRounded'
import { ModalAudioPlayer } from '../modal-audio-player'
import { SharePopover } from '../share-popover'

const iconMap = {
  article: DocumentIcon,
  asset: ImageIcon,
  audio: AudioIcon,
  document: DocumentIcon,
  image: ImageIcon,
  video: VideoIcon,
}

const getIcon = (icon) =>
  iconMap[icon] || DocumentIcon

/** The shape of data coming from Algolia's resources index */
export interface IResourceResult {
  objectID: string
  internalTitle: string,
  title: string,
  icon: ResourceIcon,
  tags: string[],
  blurb: string,
  domainObject: ILink<'Entry'> | ResourceDomainObject
  downloadAsset?: IAssetInAlgolia
  downloadExternalAssetUrl?: string
  viewAsset?: IAssetInAlgolia
  viewExternalAssetUrl?: string
  viewPage?: IPage
  securityLevel: {
    id: number,
    name: ResourceSecurityLevel,
    name_alias?: string,
    key: string,
  }
}

/** In Algolia, we store only a subset of the asset fields. */
interface IAssetInAlgolia {
  sys: { id: string }
  fields: {
    title?: string,
    description?: string,
    file: {
      fileName?: string,
      contentType?: string,
    },
  },
}

interface IResourceProps {
  id: string
  /** choose one: a dumped Contentful Resource, or a search result */
  resource: Resolved<IResource> | IResourceResult

  // provided by connectAbility
  can: Can
  currentOrigin?: string
  query?: string
}

const youtubeOrVimeoRegex = new RegExp([
  /(http:|https:|)\/\//
  , /(player.|www.)?/
  , /(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\//
  , /(video\/|embed\/|watch\?v=|v\/)?/
  , /([A-Za-z0-9._%-]*)(\&\S+)?/,
].map((r) => r.source).join(''))

// see resource-landing/buttons/viewAssetButton.tsx for tests
const privateVimeoRegex = /vimeo\.com(?:\/video)?\/(?<userid>\w+)(?:\/|\?h\=)(?<videoid>\w+)/

const imageRegex = /(?:jpe?g|png)$/i

// https://support.jwplayer.com/articles/supported-video-and-audio-formats-reference#supported-audio-files
const audioRegex = /\.(?:aac|m4a|f4a|mp3|ogg|oga)$/i

export class Resource extends React.Component<IResourceProps> {
  public render() {
    const { id, resource, can } = this.props
    const resourceClass = isEntry(resource) ? new ResourceClass(resource) : toResourceClass(resource)
    const {
      title, icon, blurb, downloadAsset, downloadExternalAssetUrl, viewAsset, viewExternalAssetUrl, viewPage,
    } = resourceClass

    // Because IAssetInAlgolia is a subset of IAsset, we can assign `downloadAsset` here even though
    // it's technically an `IAsset | IAssetInAlgolia`.  This prevents us from accidentally trying
    // to access `fields.file.url`, which does not exist in Algolia.
    const download: IAssetInAlgolia | string | null = downloadAsset || downloadExternalAssetUrl

    const canRead = can('read', resourceClass)

    const Icon = getIcon(icon)

    return <li className={`resources-list__item ${icon} ${canRead ? 'canRead' : 'cannotRead'}`}
      data-id={id} data-internaltitle={resourceClass.internalTitle}>
      <div className="resources-list__item__meta">
        <div className="resource-icon">
          <Icon />
        </div>
        <div className="content">
          <h4 className="title">{title}</h4>
          {canRead && blurb && <p className="text">{blurb}</p>}
          {!canRead && <p className="error">You do not have access to download this resource.</p>}
        </div>
      </div>
      {canRead &&
        <div className="actions">
          {
            (viewAsset && this.renderViewAsset(resourceClass)) ||
            (viewExternalAssetUrl &&
              this.renderViewAssetUrl(
                this.modifyQueryParams(viewExternalAssetUrl, resourceClass),
                resourceClass)) ||
            (viewPage && this.renderViewPage(viewPage, resourceClass))
          }
          {
            download && this.renderDownload(resourceClass, download)
          }
          {
            this.renderShare(resourceClass)
          }
        </div>}
    </li>
  }

  private eventDataAttrs(label: string, action: string) {
    try {
      const internalTitle = new URL(label).searchParams.get('internalTitle')
      if (internalTitle && internalTitle.length > 0) {
        // overwrite label with internalTitle
        label = internalTitle
      }
    } catch (ex) {
      // suppress
    }
    return {
      'data-track-event': true,
      'data-event-category': 'Search',
      'data-event-label': label,
      'data-event-action': action,
    }
  }

  private get currentOrigin() {
    return this.props.currentOrigin || location.origin
  }

  private modifyQueryParams(url: string, resource: IResource): string {
    const { query } = this.props
    const { internalTitle } = resource.fields

    let parsedUrl: URL

    try {
      parsedUrl = new URL(url)

      // the URL links offsite - dont add query string
      if (!(new RegExp(this.currentOrigin)).test(parsedUrl.origin)) {
        return url
      }
    } catch (TypeError) {
      parsedUrl = new URL(url, this.currentOrigin)
    }

    // if the url does not have a `q` parameter already - add it
    if (query && !parsedUrl.searchParams.has('q')) {
      parsedUrl.searchParams.append('q', query)
    }
    if (internalTitle && !parsedUrl.searchParams.has('internalTitle')) {
      parsedUrl.searchParams.append('internalTitle', internalTitle)
    }

    return parsedUrl.toString()
  }

  private renderDownload(resource: IResource, asset: IAssetInAlgolia | string) {
    const url = this.modifyQueryParams(
      path.join(this.currentUrlAbsolutePath(), `resources/${resource.sys.id}`),
      resource,
    )
    const downloadAttr = isAsset(asset) ? { download: asset.fields.file.fileName } : null

    return (
      <MaterialButton
        buttonText="Download"
        href={url}
        icon={<DownloadIcon />}
        rel="noopener noreferrer"
        {...downloadAttr}
        {...this.eventDataAttrs(url, 'Download')}
      />
    )
  }

  private renderShare(resource: IResource ) {
    const parsedUrl = new URL(window.location.pathname, this.currentOrigin)

    parsedUrl.searchParams.append('query', resource.fields.title)

    const urlString = parsedUrl.toString()

    return (
      <div className='single-resource'>
        <SharePopover url={urlString} />
      </div>
    )
  }

  private renderViewAssetUrl(assetUrl: string, resource: IResource) {
    if (audioRegex.test(assetUrl)) {
      return this.renderAudioPlayer(assetUrl, {
        title: resource.fields.title,
        blurb: resource.fields.blurb,
        // thumbnail: TODO
      })
    }

    if (privateVimeoRegex.test(assetUrl)) {
      const match = assetUrl.match(privateVimeoRegex)
      const url = `https://player.vimeo.com/video/${match.groups.userid}?h=${match.groups.videoid}&autoplay=true`

      return <ModalVideoPlayer
          channel={'custom'}
          url={url}
          autoplay={true}
          materialIcon={<PlayIcon />}
          ionicButton={true}
          buttonProps={this.eventDataAttrs(assetUrl, 'View')}
        />
    } else if (youtubeOrVimeoRegex.test(assetUrl)) {
      return this.renderVideo(assetUrl)

    } else if (imageRegex.test(assetUrl)) {
      return <ModalImage imageUrl={assetUrl} buttonProps={this.eventDataAttrs(assetUrl, 'View')} />

    } else {
      return (
        <MaterialButton
          buttonText="View"
          href={assetUrl}
          icon={<ViewIcon />}
          target="_blank"
          rel="noopener noreferrer"
          {...this.eventDataAttrs(assetUrl, 'View')}
        />
      )
    }
  }

  private renderViewAsset(resource: IResource) {
    return this.renderViewAssetUrl(
      this.modifyQueryParams(
        path.join(this.currentUrlAbsolutePath(), `resources/${resource.sys.id}?view=true`),
        resource,
      ),
      resource,
    )
  }

  private renderViewPage(page: IPage, resource: IResource) {
    return this.renderViewAssetUrl(
      this.modifyQueryParams(page.fields.slug, resource),
      resource)
  }

  private renderVideo(viewExternalAssetUrl: string) {
    const match = viewExternalAssetUrl.match(youtubeOrVimeoRegex)
    const channel = match[3].indexOf('youtu') > -1 ? 'youtube' : 'vimeo'
    const videoId = match[6]

    return (
      <ModalVideoPlayer
        channel={channel}
        videoId={videoId}
        autoplay={true}
        materialIcon={<PlayIcon />}
        ionicButton={true}
        buttonProps={this.eventDataAttrs(viewExternalAssetUrl, 'View')}
      />
    )
  }

  private renderAudioPlayer(
    viewExternalAssetUrl: string,
    options: {
      title: string,
      blurb?: string,
      thumbnail?: IAsset,
    },
  ) {
    return (
      <ModalAudioPlayer
        url={viewExternalAssetUrl}
        name={options.title}
        album={options.blurb}
        cover_art_url={options.thumbnail && options.thumbnail.fields.file.url}

        materialIcon={<PlayIcon />}
        ionicButton={true}
        buttonProps={this.eventDataAttrs(viewExternalAssetUrl, 'View')}
      />
    )
  }

  private currentUrlAbsolutePath() {
    const loc = window.location
    return loc.pathname.substring(0, loc.pathname.lastIndexOf('/') + 1)
  }
}

function toResourceClass(result: IResourceResult): ResourceClass {
  const fields: IResourceFields = Object.assign(
    {
      internalTitle: '',
    },
    result,
    {
      downloadAsset: toAssetClass(result.downloadAsset),
      viewAsset: toAssetClass(result.viewAsset),
      securityLevel: result.securityLevel.name,
    })

  return new ResourceClass(result.objectID, fields)
}

function toAssetClass(asset: IAssetInAlgolia): Asset {
  return asset && new Asset(asset.sys.id, asset.fields)
}

export default connectAbility(Resource)
