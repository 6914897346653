import { localFetch } from '../../../../lib/local_fetch'
import { client } from './shopify'
import { IBuildCheckoutAttributes, ICheckout, IShippingAddress } from './types'

async function logCheckoutLocally(
  { buildCheckout, checkout }: { buildCheckout: IBuildCheckoutAttributes, checkout: ICheckout },
): Promise<void> {
  const payload = new FormData()

  payload.append('cart[account_id]', buildCheckout.accountID.toString())
  if (buildCheckout.userID) {
    payload.append('cart[user_id]', buildCheckout.userID.toString())
  }
  payload.append('cart[encoded_shopify_checkout_id]', checkout.id)

  const resp = await localFetch(
    buildCheckout.checkoutLogRoute, {
      method: 'POST',
      body: JSON.stringify({
        cart: {
          account_id: buildCheckout.accountID,
          user_id: buildCheckout.userID,
          encoded_shopify_checkout_id: checkout.id,
        },
      }),
    },
  )

  if (resp.status >= 300) {
    throw new Error(`Unexpected response status: ${resp.status}`)
  }
}

const checkAddressSufficient = (address?: IShippingAddress): boolean => (
  !!address && !!address.city && !!address.province && !!address.country
)

export async function createCheckout(
  buildCheckout: IBuildCheckoutAttributes,
): Promise<ICheckout> {
  const checkout: ICheckout = await client.checkout.create({
    email: buildCheckout.email ? buildCheckout.email : undefined,
    allowPartialAddresses: true,
    shippingAddress: checkAddressSufficient(buildCheckout.shippingAddress) ? buildCheckout.shippingAddress : null,
  })
  await logCheckoutLocally({ buildCheckout, checkout })
  return checkout
}

export { checkAddressSufficient }
