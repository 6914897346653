import * as React from 'react'
import { AsyncErrorHandler } from '../../../lib/async-error-handler'
import { formatAmount } from '../lib/product'
import { MoneyV2 } from '../lib/shopify_client'
import { AddToCartButton } from './AddToCartButton'

export interface IProps {
  onAddToCart: ({ variantID, quantity }: { variantID: string, quantity: number }) => Promise<any>
  productID: string
  variantID: string
  detailPageURL: string
  imageURL: string
  ministry: string
  title: string
  defaultQuantity: number
  price: MoneyV2,
  isOutOfStock: boolean
  errorHandler?: AsyncErrorHandler

  canAddToCart: boolean
}

export class Product extends React.Component<IProps> {
  constructor(props) {
    super(props)

    this.handleAddToCart = this.handleAddToCart.bind(this)
  }

  public async handleAddToCart(event) {
    const {
      onAddToCart,
      variantID,
      defaultQuantity,
    } = this.props

    await onAddToCart({ variantID, quantity: defaultQuantity })

    event.preventDefault()
    return false
  }

  public render() {
    const {
      detailPageURL,
      imageURL,
      ministry,
      title,
      defaultQuantity,
      price,
      errorHandler,
      canAddToCart,
      isOutOfStock
    } = this.props

    const total = parseFloat(price.amount) * defaultQuantity

    return (
      <a href={detailPageURL} className="product-card">
        <div className="product-card__image">
          <img alt='' src={imageURL} />
        </div>
        <div className="product-card__content">
          <span className="product-card__ministry">{ministry}</span>
          <h3 className="product-card__title">{title}</h3>
          <span className="product-card__asset-counter">Qty: {defaultQuantity}</span>
          <div className="product-card__actions">
            <button className="product-button">View Details</button>
            {isOutOfStock ?
              <button className="product-button" disabled={true}>
                Out Of Stock
              </button> :
              <AddToCartButton
                className="product-button"
                onClick={this.handleAddToCart}
                productName={title}
                errorHandler={errorHandler}
                canAddToCart={canAddToCart}
              >
                Add to Cart
                <span className="product-button__price">{formatAmount(total, price.currencyCode)}</span>
              </AddToCartButton>}
          </div>
        </div>
      </a>
    )
  }
}
