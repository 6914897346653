import { HiddenFacets } from '@watermarkchurch/react-instantsearch-components'
import * as React from 'react'
import { Configure } from 'react-instantsearch-dom'

import { Stringable } from '../../lib/types'
import AlgoliaSearch from '../algolia-search'
import Blogs from './blogs'

const widgetName = 'blog-search'

interface IProps {
  indexName: string
  sectionTitle: string
  publishingTargetKey: string
  facetFilters?: {
    [key: string]: Stringable[] | Stringable,
  }
  operator?: 'or' | 'and'
}

export default class BlogsFeed extends React.Component<IProps> {
  public render() {
    const { indexName, sectionTitle, publishingTargetKey, facetFilters, operator } = this.props
    return (
      <AlgoliaSearch
        indexName={indexName}
        widgetName={widgetName}
      >
        <Configure hitsPerPage={4} />
        <HiddenFacets hiddenFacets={{ 'publishedProperties.key': publishingTargetKey }} />
        {facetFilters && <HiddenFacets hiddenFacets={facetFilters} operator={operator} />}
        <Blogs sectionTitle={sectionTitle} />
      </AlgoliaSearch >
    )
  }
}
