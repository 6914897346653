import React from 'react'
import WCC from 'wcc'
import { Can, connectAbility } from '../../../lib/connectors/ability'
import { Cart } from '../../../lib/models/cart'
import { Stringable } from '../../../lib/types'
import { fullTitle as productFullTitle } from '../lib/product'
import * as Client from '../lib/shopify_client'
import { withCheckout } from '../lib/with_checkout'
import { AddToCartButton } from './AddToCartButton'

enum Status {
  Loading = 'LOADING',
  NotFound = 'NOT_FOUND',
  Ready = 'READY',
}

export interface IProps {
  productID: string
  variantID: string
  accountID: Stringable

  can: Can

  fetchProduct: typeof Client.fetchProduct
  addVariantToCart: (args: { variantID: string, quantity: number }) => any
}

interface IState {
  product?: {
    title: string
    descriptionHtml: string
    variants: Array<{
      id: string
      title: string
      isOutOfStock: boolean
      image: { src: string, altText: string }
    }>
  }
  status: Status
}

export const ProductDetail = withCheckout(
  class extends React.Component<IProps, IState> {
    public static defaultProps: Partial<IProps> = {
      fetchProduct: Client.fetchProduct,
    }

    constructor(props) {
      super(props)

      this.handleAddToCart = this.handleAddToCart.bind(this)

      this.state = {
        product: null,
        status: Status.Loading,
      }
    }

    public componentDidMount() {
      const {
        productID,
        fetchProduct,
      } = this.props

      fetchProduct({ productID })
        .then((product) => {
          this.setState({
            product,
            status:  this.isProductFound({ product }) ? Status.Ready : Status.NotFound,
          })
        })
        .catch((result) => {
          this.setState({ status: Status.NotFound })
        })
    }

    public render() {
      const { status } = this.state

      switch (status) {
        case Status.Loading:
          return this.renderError({ message: 'Loading. Please Wait...' })
        case Status.NotFound:
          return this.renderError({ message: 'This product was not found!' })
        case Status.Ready:
        default:
          return this.renderPage()
      }
    }

    private renderError({ message }: { message: string }): React.ReactElement<any> {
      return (
        <div className="row">
          <div className="column medium-12 text-center m5">
            {message}
          </div>
        </div>
      )
    }

    private renderPage(): React.ReactElement<any> {
      const { product } = this.state
      const variant = this.getVariant({ product })
      const {
        descriptionHtml: descriptionHTML,
      } = product
      const image = variant.image || Client.DEFAULT_IMAGE

      const title = productFullTitle({ product, variant, showVariant: product.variants.length > 1 })
      const userID = WCC.currentUser ? WCC.currentUser.id : null

      const canAddToCart = this.props.can('create', new Cart({
        account_id: this.props.accountID,
        user_id: userID,
      }))

      return (
        <div>
          <div className="ministry__content__intro product__content__intro">
            <div className="row">
              <div className="column medium-6">
                <h3 className="text__title">{title}</h3>
                <span className="text__h4">Qty: 1</span>
                <div className="mt1">
                  {variant.isOutOfStock ?
                    <button className="product-button" disabled={true}>
                      Out Of Stock
                    </button> :
                    <AddToCartButton
                      className="btn btn-primary btn-xl"
                      onClick={this.handleAddToCart}
                      productName={title}
                      canAddToCart={canAddToCart}
                    >
                      + Add to cart
                    </AddToCartButton>}
                </div>
                <p className="mt05" dangerouslySetInnerHTML={{ __html: descriptionHTML }} />
              </div>
              <div className="column medium-6 large-5 xlarge-push-1">
                <img src={image.src} alt={image.altText} className="ministry__content__product-image" />
              </div>
            </div>
          </div>
        </div>
      )
    }

    private getVariant({ product }: { product: IState['product'] }) {
      const { variantID } = this.props

      if (variantID) {
        return product.variants.find((variant) => variant.id == variantID)
      } else {
        return product.variants[0]
      }
    }

    private isProductFound({ product }: { product: IState['product'] }): boolean {
      const variant = this.getVariant({ product })
      return !!(product && variant)
    }

    private handleAddToCart() {
      const {
        addVariantToCart,
        variantID,
      } = this.props

      return addVariantToCart({ variantID, quantity: 1 })
    }
  },
)

export default connectAbility(ProductDetail)
