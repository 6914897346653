import { localFetch } from '../../../../lib/local_fetch'
import { checkAddressSufficient } from './create_checkout'
import { client } from './shopify'
import { ICheckout, IShippingAddress } from './types'

interface IUpdateAttributesInput {
  email?: string
  shippingAddress?: IShippingAddress
  b2bQuantityThreshold: number
}

const shippingAddressEquals = (left: IShippingAddress, right: IShippingAddress): boolean => (
  (!left && !right) ||
  (!!left && !!right) &&
  left.firstName === right.firstName &&
  left.lastName === right.lastName &&
  left.company === right.company &&
  left.address1 === right.address1 &&
  left.address2 === right.address2 &&
  left.city === right.city &&
  left.province === right.province &&
  left.zip === right.zip &&
  left.country === right.country &&
  left.phone === right.phone
)

const updateCheckout = async ({
  checkout,
  attributes,
}: {
  checkout: ICheckout,
  attributes: IUpdateAttributesInput,
}): Promise<ICheckout> => {
  const totalItems = checkout.lineItems.reduce((prev, value) => value.quantity + prev, 0)
  const isB2B = totalItems >= attributes.b2bQuantityThreshold
  const expectedNote = isB2B ? null : 'B2C'

  if (checkout.note !== expectedNote) {
    checkout = await client.checkout.updateAttributes(checkout.id, { note: expectedNote })
    await localFetch('/cart', { method: 'PUT', body: JSON.stringify({ cart: { b2b: isB2B } }) })
  }

  if (checkout.email !== attributes.email) {
    checkout = await client.checkout.updateEmail(checkout.id, attributes.email)
  }

  if (!shippingAddressEquals(checkout.shippingAddress, attributes.shippingAddress)) {
    checkout = await client.checkout.updateShippingAddress(
      checkout.id,
      checkAddressSufficient(attributes.shippingAddress) ? attributes.shippingAddress : {},
    )
  }

  return checkout
}

export { shippingAddressEquals, updateCheckout }
