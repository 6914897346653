import * as React from 'react'
import { connectInfiniteHits, InfiniteHitsProvided } from 'react-instantsearch-core'
import IAlgoliaBlog from '../../lib/models/algolia-blog'

const dateOptions: Intl.DateTimeFormatOptions = {
  timeZone: 'America/Chicago',
  year: 'numeric',
  month: 'short',
  day: 'numeric',
}

interface IBlogProps {
  hit: IAlgoliaBlog
  key: string
}

const Blog = ({ hit, key }: IBlogProps) => {
  const defaultImg = '//images.ctfassets.net/lwoaet07hh7w/4aKgIwjkQii1imIf2ICXXL/' +
    '70a7167d738a1937a78cda505b28bff1/WM-WC2-slide-1920x1080.jpg'
  const renderImage = (image) => {
    if (image) {
      return <img alt='' src={`${image.file.url}`}></img>
    } else {
      return <img alt='' src={defaultImg}></img>
    }
  }

  return (
    <a href={`/blogs${hit.slug}`} className="row blog" key={key}>
      <div className="column medium-5 blog__image">
        {renderImage(hit.thumbnailImage)}
      </div>
      <div className="column medium-7 blog__text">
        <h4 className="text__h4 blog__heading">{hit.title}</h4>
        <span className="blog__caption">
          <span>{new Date(hit.date).toLocaleString('en-US', dateOptions)}</span>
        </span>
        {hit.summary &&
          <p className="blog__summary">
            {hit.summary.summary}
          </p>
        }
      </div>
    </a>
  )
}

interface IConnectInfiniteHitsProps extends InfiniteHitsProvided {
  sectionTitle: string
}

export default connectInfiniteHits<IConnectInfiniteHitsProps, any>(({ hits, hasMore, refineNext, sectionTitle }) => (
  <div className="blogs-feed">
    <h2 className="blogs-feed__title">
      {sectionTitle}
    </h2>
    {hits.map((hit) =>
      <Blog hit={hit} key={hit.objectID} />,
    )}
    <div className="blogs-feed__button">
      <button disabled={!hasMore} onClick={() => { refineNext() }}>
        MORE
      </button>
    </div>
  </div>
))
