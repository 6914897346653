import * as React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { darken, fade } from '@material-ui/core/styles/colorManipulator'

const styles = makeStyles({
  root: ({ domainColor }: { domainColor: string }) => ({
    '& .MuiTreeItem-root': {
      '& .MuiTreeItem-group': {
        backgroundColor: fade(domainColor, .25),
      },
      '&.leaf-resource-list': {
        '& .MuiTreeItem-group': {
          backgroundColor: domainColor,
        },
      },
    },
    '& .resource-icon': {
      '& .MuiSvgIcon-root': {
        color: darken(domainColor, .2),
      },
    },
  }),
})

export const DomainStyles: React.FunctionComponent<{ domainColor: string }> = (props) =>
  <div className={ styles(props).root }>{ props.children }</div>
