import * as React from 'react'
import { SearchBox } from 'react-instantsearch-dom'

export default () =>
  <div className="secondary-nav">
    <div className="row">
      <div className="column">
        <div className="secondary-nav__inner-container">
          <ul className="secondary-nav__list">
            <li className="secondary-nav__item secondary-nav__search">
              <div className="expandable-search">
                <SearchBox translations={ { placeholder: 'Search...' } } />
                <i className="icon ion-ios-search-strong"></i>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
