import * as React from 'react';
import { Configure } from 'react-instantsearch-dom';
import { AlgoliaSearch } from '@watermarkchurch/react-instantsearch-components';
import searchInsights from 'search-insights';
import qs from 'qs';
import { pick } from './utils/pick';
import { LocationSearchResults } from './location-search-results';
import { TabNavItem, TabPane } from './bootstrap/tabs';
import SearchConfig from './search-config';
import { DefaultLayouts } from './layouts';
import { MapRefinementKeys } from './connectors/connectMapRefinement';
import { present } from './utils';
export { parseTzlessDate } from './utils/parseTzlessDate';
export { DefaultLocationMarker } from './map-pane';
export { DefaultListItem, calculateDistanceText } from './list-pane/list-item';
export * from './utils';
export * from './types';
export default class LocationMap extends React.Component {
    aa;
    indexNameMemo;
    constructor(props) {
        super(props);
        const activeTab = props.includeVirtualEvents ?
            (window.location.hash == '#virtual' ? 'virtual' : 'in-person') : undefined;
        this.state = {
            activeTab
        };
        this.aa = props.aa;
        this.indexNameMemo = (props.includeVirtualEvents && activeTab == 'virtual') ? props.virtualEventsIndex : props.indexName;
    }
    componentDidMount() {
        // Initialize searchInsights if not injected
        if (!this.aa) {
            searchInsights('init', {
                appId: this.props.algoliaAppId,
                apiKey: this.props.algoliaApiKey
            });
            this.aa = searchInsights;
        }
    }
    render() {
        const { algoliaAppId, algoliaApiKey, virtualEventsIndex, includeVirtualEvents, domainObject, aa, ...rest } = this.props;
        const { activeTab } = this.state;
        // Analytics are on by default
        const sendAnalytics = this.props.sendAnalytics !== false;
        const indexName = (includeVirtualEvents && activeTab == 'virtual') ? this.props.virtualEventsIndex : this.props.indexName;
        if (this.indexNameMemo !== indexName) {
            // Clear out the search state when the index changes, but preserve the hash
            const url = new URL(window.location.href);
            url.search = '';
            window.history.replaceState({}, '', url.toString());
            this.indexNameMemo = indexName;
        }
        const algoliaAnalytics = {
            indexName,
            aa: this.aa,
            sendAnalytics
        };
        const extraConfig = {
            clickAnalytics: sendAnalytics,
            analytics: sendAnalytics
        };
        if (!includeVirtualEvents) {
            return React.createElement(AlgoliaSearch, { apiKey: algoliaApiKey, appId: algoliaAppId, indexName: indexName, widgetName: 'location-map-widget', storeStateInQuery: true, searchStateToUrl: searchStateToUrl, urlToSearchState: urlToSearchState },
                React.createElement(SearchConfig, { domainObject: domainObject }),
                React.createElement(LocationSearchResults, { ...rest, Layout: DefaultLayouts['in-person'], algoliaAnalytics: algoliaAnalytics }),
                React.createElement(Configure, { ...extraConfig }));
        }
        const { translate } = this.props;
        return React.createElement(AlgoliaSearch, { apiKey: algoliaApiKey, appId: algoliaAppId, 
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            indexName: indexName, widgetName: 'location-map-widget', storeStateInQuery: true, searchStateToUrl: searchStateToUrl, urlToSearchState: urlToSearchState },
            React.createElement(SearchConfig, { domainObject: domainObject }),
            React.createElement("div", { className: `row tab-header` },
                React.createElement(TabNavItem, { id: "in-person", bookmark: translate('locationMap.tabs.in_person.bookmark'), active: activeTab == 'in-person', translations: {
                        header: translate('locationMap.tabs.in_person.header')
                    }, storeState: true, onTabSelected: (id) => this.setState({ activeTab: id }) }),
                React.createElement(TabNavItem, { id: "virtual", bookmark: translate('locationMap.tabs.virtual.bookmark'), active: activeTab == 'virtual', translations: {
                        header: translate('locationMap.tabs.virtual.header')
                    }, storeState: true, onTabSelected: (id) => this.setState({ activeTab: id }) })),
            React.createElement("div", { className: `row tab-content` },
                React.createElement(TabPane, { id: "in-person", active: activeTab == 'in-person' }, activeTab == 'in-person' &&
                    React.createElement(LocationSearchResults, { ...rest, Layout: DefaultLayouts['in-person'], algoliaAnalytics: algoliaAnalytics })),
                React.createElement(TabPane, { id: "virtual", active: activeTab == 'virtual' }, activeTab == 'virtual' &&
                    React.createElement(LocationSearchResults, { ...rest, Layout: DefaultLayouts['virtual'], algoliaAnalytics: algoliaAnalytics }))),
            React.createElement(Configure, { ...extraConfig }));
    }
}
/**
 * Turn an Algolia search state into a relative URL for a hyperlink
 */
const createURL = (state) => `?${qs.stringify(state)}${window.location.hash}`;
/*
 * Create an absolute URL suitable for the history from the current location and search state.
 */
function searchStateToUrl(location, searchState) {
    if (!searchState) {
        return '';
    }
    const actualSearchState = searchState;
    // Whitelist only the parts of the search state we need to serialize
    const serializedSearchState = {
        query: actualSearchState.query,
        mapBounds: actualSearchState.mapBounds ? pick(actualSearchState.mapBounds, MapRefinementKeys) : undefined,
        refinementList: actualSearchState.refinementList,
        toggle: actualSearchState.toggle,
    };
    // delete empty keys so they don't get serialized to the query string
    deleteEmptyKeys(serializedSearchState);
    return new URL(createURL(serializedSearchState), location.toString()).toString();
}
/**
 * Parse the current search state out of a URL.
 */
function urlToSearchState(location) {
    const parsed = qs.parse(location.search.slice(1));
    return {
        query: parsed.query,
        mapBounds: {
            ...parsed.mapBounds,
            selectedLocation: parsed.mapBounds?.selectedLocation ? {
                ...parsed.mapBounds.selectedLocation,
                lat: parseFloat(parsed.mapBounds.selectedLocation.lat),
                lng: parseFloat(parsed.mapBounds.selectedLocation.lng),
                isVirtual: (parsed.mapBounds.selectedLocation.isVirtual === 'true') || undefined // qs.parse returns 'true' as a string
            } : undefined,
            selectedPlace: parsed.mapBounds?.selectedPlace ? {
                ...parsed.mapBounds.selectedPlace,
                location: parsed.mapBounds.selectedPlace.location ? {
                    lat: parseFloat(parsed.mapBounds.selectedPlace.location.lat),
                    lng: parseFloat(parsed.mapBounds.selectedPlace.location.lng)
                } : undefined,
                viewport: parsed.mapBounds.selectedPlace.viewport ? {
                    east: parseFloat(parsed.mapBounds.selectedPlace.viewport.east),
                    north: parseFloat(parsed.mapBounds.selectedPlace.viewport.north),
                    south: parseFloat(parsed.mapBounds.selectedPlace.viewport.south),
                    west: parseFloat(parsed.mapBounds.selectedPlace.viewport.west)
                } : undefined
            } : undefined
        },
        refinementList: parsed.refinementList,
        toggle: parsed.toggle
    };
}
function deleteEmptyKeys(obj) {
    Object.keys(obj).forEach(key => {
        if (obj[key] && typeof obj[key] === 'object') {
            deleteEmptyKeys(obj[key]);
        }
        else if (!present(obj[key])) {
            delete obj[key];
        }
    });
}
