
import React from "react"
import path from 'path'
import { present } from '../../../lib/utils/present'
import { Resolved } from "../../../lib/contentful"
import { IResource } from "../../../lib/contentful/generated"
import MaterialButton from "../../material-button"
import { default as DownloadIcon } from '@material-ui/icons/GetAppRounded'
import { IResourceResult } from "../../resource-search/search_results"
import { isEntry } from "../../../lib/contentful"

export function DownloadAssetButton({ resource, icon, baseUrl }: { resource: IResource | IResourceResult, icon?: JSX.Element, baseUrl?: string }) {
  const resourceId = isEntry(resource) ? resource.sys.id : resource.id
  const resourceFields = isEntry(resource) ? resource.fields : resource
  const { downloadAsset, downloadExternalAssetUrl } = resourceFields

  const hasDownload = present(downloadExternalAssetUrl) ||
    present(downloadAsset)

  if (!hasDownload) { return null }

  const downloadUrl = path.join(baseUrl || window.location.pathname, `./resources/${resourceId}`)
  const downloadFilename = isEntry(downloadAsset) && downloadAsset.fields.file.fileName || ''

  const trackingProps = {
    'data-track-event': true,
    'data-event-category': 'Search',
    'data-event-label': downloadUrl,
    'data-event-action': 'Download',
  }

  return (
    <MaterialButton
      buttonText="Download"
      href={downloadUrl}
      icon={icon || <DownloadIcon />}
      rel="noopener noreferrer"
      download={downloadFilename.length > 0 ? downloadFilename : undefined}
      {...trackingProps}
    />
  )
}
