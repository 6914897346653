import * as React from 'react'
import * as toastr from 'toastr'
import { IResource } from '../../lib/contentful/generated'
import { Resource } from './resource'
import { downloadResources } from '../../lib/utils/download_resources'
import { ResourceActionMenu } from '../resource-action-menu'
import { copyLink } from '../../lib/utils/copyLink'

interface IProps {
  title: string,
  blurb?: string,
  resources: IResource[]
  limit?: number
  hideViewMore?: boolean

  /**
   * base_url is the full URL of the resource set path, i.e. leader-resources/s/{id}
   * If not set, this defaults to the current window.location.pathname
   */
  base_url?: string
}

export function ResourceSet({ title, blurb, resources, base_url, limit, hideViewMore }: IProps) {
  const [showAll, setShowAll] = React.useState(false)

  const [copied, setCopied] = React.useState(false)

  let visibleResources = resources || []
  if (limit && !showAll) {
    visibleResources = visibleResources.slice(0, limit)
  }

  return (
    <div className='resource-set'>
      <div className="resource-set__header">
        <h2>{title}</h2>
        <ResourceActionMenu>
          <span
            data-tooltip='Audio and video files are not included'
            data-placement='top'
            onClick={() => downloadResources(resources, title)}>Download All</span>

          <span style={{cursor: 'pointer'}}
              onClick={(e) => {
                e.preventDefault()
                const copyUrl = new URL(window.location.origin)
                copyUrl.pathname = base_url || window.location.pathname
                copyLink(copyUrl.toString())
                  .then(() => {
                    toastr.success('Link copied to clipboard!')
                    setCopied(true)
                    setTimeout(() => setCopied(false), 5000)
                  })
                  .catch((ex) => {
                    console.error(ex)
                    toastr.error('Failed to copy link to clipboard')
                  })
              }}>
              {copied ?
                'Link copied!' :
                'Copy Link'}
            </span>
        </ResourceActionMenu>
      </div>
      {blurb && <p className='resource-set__blurb'>{blurb}</p>}
      <div className='resource-set__resources'>
        {visibleResources.map((resource, index) => (
          <Resource key={index} resource={resource} baseUrl={base_url} />
        ))}
        {visibleResources.length < resources?.length && !hideViewMore &&
          <a className='resource-set__load-more' onClick={() => setShowAll(true)}>View More</a>
        }
      </div>
    </div>
  )
}
