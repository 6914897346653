import * as React from 'react'

import { default as ExpandLess } from '@material-ui/icons/ExpandLess'
import { default as ExpandMore } from '@material-ui/icons/ExpandMore'
import TreeItem from '@material-ui/lab/TreeItem'

import { Resolved } from '../../lib/contentful'
import {
  IResource,
  IResourceSet,
} from '../../lib/contentful/generated'
import { expectResolved, isResolved } from '../../lib/contentful/utils'

import { SharePopover } from '../share-popover'
import { branchURL, renderLabel } from './helpers'
import Resource from './resource'

export class Leaf extends React.Component<IResourceSet | Resolved<IResourceSet>> {
  public render() {
    const leaf = this.props
    const share = <SharePopover url={branchURL(leaf.sys.id)}/>

    return <TreeItem
      nodeId={leaf.sys.id}
      label={renderLabel(leaf)}
      expandIcon={
        <>{share}<ExpandMore/></>
      }
      collapseIcon={
        <>{share}<ExpandLess/></>
      }
      className="leaf-resource-list"
    >
      {this.renderResources(leaf) }
    </TreeItem>
  }

  private renderResources(leaf: IResourceSet | Resolved<IResourceSet>) {
    if (!isResolved(leaf, 2)) { return <div className="loading-bar"/> }

    const resources = leaf.fields.resources as IResource[]

    return <ul className="resources-list">
      {resources && resources.filter((r) => r).map((resource) =>
        <Resource id={resource.sys.id} resource={expectResolved(resource)} key={resource.sys.id} />,
      )}
    </ul>
  }
}
