import { client } from './shopify'
import { ICheckout } from './types'

interface IUpdateItemQuantityArgs {
  checkoutID: string
  lineItemID: string
  quantity: number
}

export async function updateItemQuantity(
  { checkoutID, lineItemID, quantity }: IUpdateItemQuantityArgs,
): Promise<ICheckout> {
  const lineItemsToUpdate = [
    { id: lineItemID, quantity },
  ]
  const checkout: ICheckout = await client.checkout.updateLineItems(checkoutID, lineItemsToUpdate)
  return checkout
}
