import * as React from 'react'
import * as toastr from 'toastr'
import { StateResultsProvided, connectStateResults } from 'react-instantsearch/connectors'
import { Stats } from 'react-instantsearch/dom'
import { IPage, ResourceSecurityLevel, IResource, isResource, isPage } from '../../lib/contentful/generated'
import { ILink } from '../../lib/contentful'
import { ResourceDomainObject } from '../../lib/contentful/generated'
import { PageHit } from './hits/page-hit'
import { ResourceHit } from './hits/resource-hit'
import { Resolved, isEntry } from '../../lib/contentful'
import { fetchEntry } from '../../lib/connectors/contentful'
import { downloadResources } from '../../lib/utils/download_resources'
import { ResourceActionMenu } from '../resource-action-menu'
import { copyLink } from '../../lib/utils/copyLink'

interface IAssetInAlgolia {
  sys: { id: string }
  fields: {
    title?: string,
    description?: string,
    file: {
      url?: string,
      details?: {
        size?: number,
      }
      fileName?: string,
      contentType?: string,
    },
  },
}

export interface IResourceResult {
  id: string,
  internalTitle: string,
  title: string,
  icon: string,
  blurb: string,
  tags: string[],
  downloadAsset: IAssetInAlgolia,
  domainObject: ILink<'Entry'> | ResourceDomainObject
  downloadExternalAssetUrl?: string
  viewAsset: IAssetInAlgolia,
  viewExternalAssetUrl?: string
  viewPage: IPage,
  securityLevel: {
    id: number,
    name: ResourceSecurityLevel,
    name_alias?: string,
    key: string,
  },
  object_type: string,
}

interface IPageResult {
  id: string,
  title: string,
  slug: string,
  isResourceLandingPage: boolean,
  theme: {
    id: string,
    key: string,
    title: string,
    viewPath: string,
  }
  metaDescription: string,
}

export interface IResourceResultCompositeHit {
  objectID: string,
  Page: IPageResult,
  Resource: IResourceResult,
  object_type: 'Page' | 'Resource',
}

interface IProp extends StateResultsProvided<IResourceResultCompositeHit> {
  isTyping: boolean
}

function raiseUnknownObjectTypeError(object_type: never): never {
  throw new Error(`Unknown object_type: ${object_type}`)
}

const SearchResults: React.FC<IProp> = (props) => {
  const [sharedResult, setSharedResult] = React.useState<Resolved<IResource> | Resolved<IPage> | null>(null)

  const urlParams = new URLSearchParams(window.location.search)
  const resourceId = props.isTyping ? null : urlParams.get('resourceId')

  React.useEffect(() => {
    if (!resourceId) return

    const fetchResult = async () => {
      const result = await fetch(resourceId)

      if(isEntry(result)) {
        if(isResource(result)) {
          setSharedResult(result)
        } else if(isPage(result)) {
          setSharedResult(result)
        }
      }
    }
    fetchResult()
  }, [resourceId])

  let hit: JSX.Element | null = null

  if (sharedResult && !props.isTyping) {
    if (isResource(sharedResult)) {
      hit = <ResourceHit resource={sharedResult} isTyping={props.isTyping} />
    } else if (isPage(sharedResult)) {
      hit = <PageHit resource={sharedResult} isTyping={props.isTyping} />
    }
  } else {
    hit = null
  }

  return (
    <div className='ais-SearchResults'>
      <div className='widget-resource-search__header'>
        <Stats
          translations={{
            stats(nbHits) {
              return `${nbHits} matching resource${nbHits !== 1 ? 's' : ''}`
            },
          }} />

        <ResourceActionMenu>
          <DownloadSearchResultsButton {...props} />
          <CopySearchResultsButton />
        </ResourceActionMenu>
      </div>

      {hit}
      {props.searchResults?.hits.filter((hit) => {
        if(!resourceId) return true
        return hit.objectID.includes(resourceId) ? false : true
      }).map((hit) => {
        switch (hit.object_type) {
          case 'Page':
            return <PageHit key={hit.objectID} resource={hit} isTyping={props.isTyping} />
          case 'Resource':
            return <ResourceHit key={hit.objectID} resource={hit} isTyping={props.isTyping} />
          default:
            return raiseUnknownObjectTypeError(hit.object_type)
        }
      })}
    </div>
  )
}

const fetch = async<T extends IResource | IPage>(id: string, level = 2): Promise<Resolved<T>> => {
  const fetched = await fetchEntry(id, level)
  if (isResource(fetched) || isPage(fetched)) {
    return fetched as Resolved<T>
  }
  throw new Error(`Unexpected content type returned! ${fetched.sys.contentType.sys.id}`)
}

const ConnectedSearchResults = connectStateResults(SearchResults)

export default ConnectedSearchResults

function CopySearchResultsButton() {
  const [copied, setCopied] = React.useState(false)

  return <span style={{cursor: 'pointer'}}
    onClick={(e) => {
      e.preventDefault()
      copyLink(window.location.toString())
        .then(() => {
          toastr.success('Link copied to clipboard!')
          setCopied(true)
          setTimeout(() => setCopied(false), 5000)
        })
        .catch((ex) => {
          console.error(ex)
          toastr.error('Failed to copy link to clipboard')
        })
    }}>
    {copied ? 'Link Copied!' : 'Copy Link'}
  </span>
}


function DownloadSearchResultsButton(props: StateResultsProvided<IResourceResultCompositeHit>) {
  const [downloading, setDownloading] = React.useState(false)
  const [error, setError] = React.useState<Error | null>(null)
  
  const onClick = React.useCallback(() => {
    setDownloading(true)
    setError(null)
    downloadResources(props.searchResults.hits, props.searchState.query)
      .then(() => {
        toastr.success('Resource download complete!')
      })
      .catch((ex) => {
        console.error(ex)
        setError(ex)
        toastr.error('Failed to download resources!')
      })
      .finally(() => {
        setDownloading(false)
      })
  }, [props.searchResults?.hits, props.searchState?.query])
  
  if (downloading) {
    return <span>Downloading...</span>
  }
  
  if (error) {
    return <span
        data-tooltip='Click to try again'
        data-placement='top'
        onClick={onClick}>
          Something went wrong!
      </span>
  }

  return <span
            data-tooltip='Audio and video files are not included'
            data-placement='top'
            onClick={onClick}>
              Download All
          </span>
}
