import * as React from 'react'
import * as toastr from 'toastr'
import { IResource } from '../../../lib/contentful/generated'
import { default as ShareIcon } from '@material-ui/icons/ShareRounded'
import { default as DoneIcon } from '@material-ui/icons/DoneRounded'
import { isEntry } from '../../../lib/contentful'
import { IResourceResult } from '../../resource-search/search_results'
import { copyLink } from '../../../lib/utils/copyLink'

export function CopyAssetButton({ resource, icon, baseUrl }: { resource: IResource | IResourceResult, icon?: (copied: boolean) => JSX.Element, baseUrl?: string }) {
  const resourceId = isEntry(resource) ? resource.sys.id : resource.id
  const downloadUrl = new URL(window.location.origin)
  downloadUrl.pathname = baseUrl || window.location.pathname
  downloadUrl.search = window.location.search
  downloadUrl.searchParams.set('resourceId', resourceId)

  const [copied, setCopied] = React.useState(false)

  if (!icon) {
    icon = (copied: boolean) => {
      if (copied) {
        return <DoneIcon />
      } else {
        return <ShareIcon />
      }
    }
  }

  return <a style={{cursor: 'pointer'}}
    onClick={(e) => {
      e.preventDefault()
      copyLink(downloadUrl.toString())
        .then(() => {
          toastr.success('Link copied to clipboard!')
          setCopied(true)
          setTimeout(() => setCopied(false), 5000)
        })
        .catch((ex) => {
          console.error(ex)
          toastr.error('Failed to copy link to clipboard')
        })
    }}>
    {icon(copied)}
  </a>
}
