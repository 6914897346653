import * as React from 'react'

import {
  AlgoliaSearch,
  HiddenFacets,
  IRefinementDefinition,
} from '@watermarkchurch/react-instantsearch-components'
import { Configure } from 'react-instantsearch-dom'

import SearchBar from './search-bar'
import ConnectedSearchResults from './search-results'

const widgetName = 'media-search'

interface IProps {
  index: string
  hitsPerPage?: number
  hiddenFacets?: IRefinementDefinition
  facetFilters?: string | string[]
  translations: {
    facets: {
      [key: string]: string,
    },
  }
}

export default class MediaSearch extends React.Component<IProps> {
  public render() {
    const { index, facetFilters, hiddenFacets, hitsPerPage, translations } = this.props

    return (
      <AlgoliaSearch
        indexName={index}
        widgetName={widgetName}
      >
        <Configure hitsPerPage={hitsPerPage || 50} />
        {hiddenFacets &&
          <HiddenFacets hiddenFacets={hiddenFacets} />}

        <SearchBar facetFilters={facetFilters} translations={ translations.facets } />
        <ConnectedSearchResults />
      </AlgoliaSearch >
    )
  }
}
