import * as React from 'react'

import {IEntry, JsonObject, KnownContentType, Resolved} from '../contentful'
import { TypeDirectory } from '../contentful/generated'
import { fetchUrl } from '../contentful/utils'
import { localFetch } from '../local_fetch'
import { Omit } from '../types'

/** Fetches a single entry by ID, resolving it to the given include depth.  Include must be > 1 */
export async function fetchEntry(id: string, include?: number): Promise<IEntry<JsonObject>>

/**
 * Fetches an entry given a literal content type
 *
 * If you provide an explicit string literal like 'resourceTree', the type system
 * can figure out which Interface to return.  In this case it will be a `IResourceTree`.
 */
export async function fetchEntry<CT extends KnownContentType>(id: string, contentType: CT): Promise<TypeDirectory[CT]>
/**
 * Fetches an entry given a literal content type, resolved to the given depth.
 *
 * If you provide an explicit string literal like 'resourceTree', the type system
 * can figure out which Interface to return.  In this case it will be a `Resolved<IResourceTree>`.
 */
export async function fetchEntry<CT extends KnownContentType>(id: string, contentType: CT, include: number):
  Promise<Resolved<TypeDirectory[CT]>>
/**
 * Fetches an entry with a content type that is not known at compile time but known at runtime.
 *
 * The type system cannot know what Interface to return, so the result is an IEntry
 */
export async function fetchEntry(id: string, contentType?: Exclude<string, KnownContentType>, include?: number):
  Promise<IEntry<JsonObject>>

// function implementation
export async function fetchEntry(
  id: string,
  contentType: string | number = null,
  include: number = null,
): Promise<any> {
  const resp = await localFetch(fetchUrl(id, contentType, include))
  if (resp.status == 404) {
    return null
  }
  if (resp.status != 200) {
    throw new Error(`Unexpected status code ${resp.status} for ${resp.url}`)
  }

  return resp.json()
}

interface IProvided {
  fetchEntry: typeof fetchEntry
}

export function connectContentfulFetch<TProps extends IProvided>(
  WrappedComponent: React.ComponentType<TProps>,
): React.ComponentType<Omit<TProps, IProvided>> {
  return (props) => <WrappedComponent {...props as TProps} fetchEntry={fetchEntry} />
}
