import * as React from 'react'
import WCC from 'wcc'

import { AsyncErrorHandler } from '../../../lib/async-error-handler'
import { Can, connectAbility } from '../../../lib/connectors/ability'
import { Cart } from '../../../lib/models/cart'
import { Stringable } from '../../../lib/types'
import { fullTitle as fullProductTitle } from '../lib/product'
import { detailPageURL } from '../lib/product'
import * as Client from '../lib/shopify_client'
import { MoneyV2 } from '../lib/shopify_client'
import { withCheckout } from '../lib/with_checkout'
import { Product } from './Product'

interface IProps {
  ministryName: string
  collectionID: string
  sourceURL: string
  accountId: Stringable

  can: Can

  fetchProducts: typeof Client.fetchProducts
  addVariantToCart: (args: { variantID: string, quantity: number }) => any
}

interface IState {
  products: Array<{
    id: string,
    title: string,
    variants: Array<{
      id: string
      title: string
      price: MoneyV2
      isOutOfStock: boolean
      image: { src: string, altText: string }
    }>
  }>

  error?: Error
  wait?: any
}

export const ProductGrid = withCheckout(
  class ProductGridImpl extends React.Component<IProps, IState> {
    public static defaultProps: Partial<IProps> = {
      fetchProducts: Client.fetchProducts,
    }

    private errorHandler: AsyncErrorHandler

    constructor(props: IProps) {
      super(props)

      this.errorHandler = new AsyncErrorHandler(this)
      this.handleAddToCart = this.errorHandler.wrap(this, this.handleAddToCart, { propagate: true })
      this.loadProducts = this.errorHandler.wrap(this, this.loadProducts)

      this.state = {
        products: [],
      }
    }

    public UNSAFE_componentWillMount() {
      void this.loadProducts()
    }

    public render() {
      const { products, error, wait } = this.state
      const {
        ministryName,
        sourceURL,
        can,
        accountId,
      } = this.props
      const userID = WCC.currentUser ? WCC.currentUser.id : null

      const canAddToCart = can('create', new Cart({
        account_id: accountId,
        user_id: userID,
      }))

      const renderedProducts = products.map((product) => {
        const variantCount = product.variants.length

        return product.variants.map((variant) => {
          const image: Client.IImage = variant.image || Client.DEFAULT_IMAGE

          const url = detailPageURL({
            prefix: sourceURL,
            title: product.title,
            productID: product.id,
            variantID: variant.id,
            accountID: accountId,
          })

          return (
            <div key={variant.id} className={`column medium-6 ${wait ? 'wait' : ''}`}>
              <Product
                onAddToCart={this.handleAddToCart}
                productID={product.id}
                variantID={variant.id}
                title={fullProductTitle({ product, variant, showVariant: variantCount > 1 })}
                detailPageURL={url}
                imageURL={image.src}
                ministry={ministryName}
                price={variant.price}
                defaultQuantity={1}
                isOutOfStock={variant.isOutOfStock}
                errorHandler={this.errorHandler}
                canAddToCart={canAddToCart}
              />
            </div>
          )
        })
      })

      return (
        <div className="row">
          {error && this.renderError(error)}
          {renderedProducts}
        </div>
      )
    }

    private renderError(error: Error) {
      let errors: any[]
      try {
        errors = JSON.parse(error.message)
      } catch {
        errors = []
      }

      if (!Array.isArray(errors)) {
        errors = [errors]
      }
      const messages: string[] = errors.map((e) => e.message).filter((m) => m)
      if (messages.length <= 0) {
        return <div className="error">
                An unknown error occurred! Please try refreshing the page.
                <hr />
               </div>
      }

      return <div className="error">
              There are issues with your account information:
              <blockquote>
                {messages.map((m) => <span key={m}>{m}<br/></span>)}
              </blockquote>
              Please correct them on the <a href="/church">Church Profile page!</a>
              <hr />
             </div>
    }

    private async handleAddToCart({ variantID, quantity }: { variantID: string, quantity: number }) {
      const {
        addVariantToCart,
      } = this.props

      await addVariantToCart({ variantID, quantity })
    }

    private async loadProducts() {
      const {
        collectionID,
        fetchProducts,
      } = this.props

      const products = await fetchProducts({ collectionID })
      this.setState({ products })
    }
  },
)

export default connectAbility(ProductGrid)
