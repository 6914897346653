import * as React from 'react'
import {
  connectCurrentRefinements,
  CurrentRefinementsProvided,
} from 'react-instantsearch-core'

const SearchReset = ({ refine }: CurrentRefinementsProvided) => (
  <div className="row">
    <div className="col-12 formatted-content">
      <p>
        There are no results.
        &nbsp;
        <a href="#" onClick={(evt) => {
            evt.preventDefault()
            refine((ss) => {
              return {
                ...ss,
                query: '',
              }
            })
            setTimeout(() => {
              $('.ais-SearchBox-input').focus()
            }, 400)
          }}>
          Please try again.
        </a>
      </p>
    </div>
  </div>
)

const ConnectedSearchReset = connectCurrentRefinements(SearchReset)

export { ConnectedSearchReset }
