import { Stringable } from '../types'

export default interface ICart {
  account_id: Stringable,
  user_id: Stringable,
  encoded_shopify_checkout_id?: string,
}

export class Cart {
  // uglify-js changes the constructor name, but we need it
  // for permissions checking
  public static getConstructorName() {
    return 'Cart'
  }

  // tslint:disable:variable-name
  public readonly account_id: Stringable
  public readonly user_id: Stringable
  public readonly encoded_shopify_checkout_id: string

  constructor(cart: ICart) {
    Object.assign(this, cart)
  }

}
