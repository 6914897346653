import * as React from 'react'
import { ICurrentUser } from 'wcc'
import { withErrorHandling } from '../../lib/async-error-handler'
import { connectCurrentUser } from '../../lib/connectors/pageContext'
import { localFetch } from '../../lib/local_fetch'

interface IProps {
  user: ICurrentUser,
  client?: {
    id: number,
    integration: { id: number, name: string, key: string },
  }
}

interface IToken {
  id: string,
  integration_client_id: number,
  created_at: string,
  updated_at: string
}

interface IState {
  refreshTokens: IToken[],
  error?: any,
  wait?: boolean
}

interface IRefreshTokenProps {
  token: IToken,
  delete: (token: string) => any
}

export class RefreshTokenList extends React.Component<IProps, IState> {

  constructor(props, context) {
    super(props, context)
    this.state = { refreshTokens: [], wait: false }

    this.revokeToken = withErrorHandling(this, this.revokeToken)
    this.loadTokens = withErrorHandling(this, this.loadTokens)
    this.createToken = withErrorHandling(this, this.createToken)
  }

  public componentDidMount() {
    // tslint:disable-next-line:no-floating-promises background task
    this.loadTokens()
  }

  public render() {
    const {error, refreshTokens, wait} = this.state

    return <div className={`refresh-token ${wait && 'wait'}`}>
      {error && <div className="error">
          There was an error performing that action:
          <pre>{error.toString()}</pre>
          Please try reloading the page
        </div>}
      <div className="refresh-token_add">
        <button onClick={this.createToken}>Create a new token</button>
      </div>
      {refreshTokens.length <= 0 && <span>No tokens created</span>}
      {refreshTokens.length > 0 && <table className="refresh-token__list">
        <thead>
          <tr>
            <td>Token</td><td>Created At</td><td>Last Used</td><td></td>
          </tr>
        </thead>
        <tbody>
          {refreshTokens.map((t) => <this.RefreshToken key={t.id} token={t} delete={this.revokeToken} />)}
        </tbody>
      </table>}
    </div>
  }

  private async revokeToken(token: string) {
    const { refreshTokens:  tokens } = this.state

    const resp = await localFetch(`/api/tokens/${encodeURIComponent(token)}`, {
      method:  'DELETE',
    })
    if (resp.status != 204) {
      throw new Error(`Unexpected response code ${resp.status}`)
    }
    tokens.splice(tokens.findIndex((t) => t.id == token), 1)
    this.setState({
      refreshTokens:  tokens,
    })
  }

  private async loadTokens() {
    const query = this.props.client ? `?client_id=${this.props.client.id}` : ''
    const resp = await localFetch(`/api/tokens${query}`, {})
    if (resp.status != 200) {
      throw new Error(`Unexpected response code ${resp.status}`)
    }
    const json = await resp.json()
    this.setState({
      refreshTokens: json.refresh_tokens,
    })
  }

  private async createToken() {
    const body = this.props.client &&
      `client_id=${this.props.client.id}`
    const resp = await localFetch(`/api/tokens/`, {
      method:  'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body,
    })
    if (resp.status != 200) {
      throw new Error(`Unexpected response code ${resp.status}`)
    }
    const json = await resp.json()
    this.setState({
      refreshTokens: [...this.state.refreshTokens, json.refresh_token],
    })
  }

  private RefreshToken = (props: IRefreshTokenProps) => {
    const { created_at, updated_at } = props.token
    const createdAt = new Date(Date.parse(created_at))
    const updatedAt = new Date(Date.parse(updated_at))

    return <tr>
      <td><pre>{props.token.id}</pre></td>
      <td>{createdAt.toLocaleString()}</td>
      <td>{updatedAt.toLocaleString()}</td>
      <td><button onClick={() => props.delete(props.token.id)}>Revoke</button></td>
    </tr>
  }
}

export default connectCurrentUser(RefreshTokenList)
