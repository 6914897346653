import * as React from 'react'
import { connectHits } from 'react-instantsearch/connectors'

const Hit = ({ hit }) =>
  <div className="item">
    <a href={ hit.slug || '' }
       className="model-card"
       style={ { backgroundColor: hit.backgroundColor } }
    >
      <span
        className="model-card__category"
        style={ { color: hit.foregroundColor } }
      >
        { hit.abstractTitle }
      </span>
      <div className="model-card__logo">
        <img alt='' src={ hit.logoUrl || hit.thumbnailUrl } />
      </div>
    </a>
  </div>

export default connectHits(({ hits }) => (
  <div className="row item-list">
    {hits.map((hit) =>
      <Hit hit={hit} key={ hit.objectID }/>,
    )}
  </div>
))
