import { ensureCheckoutID } from './ensure_checkout_id'
import { client } from './shopify'
import { IBuildCheckoutAttributes, ICheckout, IMappedCustomAttributes } from './types'

interface IAddVariantToCartArgs {
  checkoutID: string
  buildCheckoutAttributes: IBuildCheckoutAttributes
  variantID: string
  quantity: number
  customAttributes: IMappedCustomAttributes
}

export async function addVariantToCart(
  { checkoutID, buildCheckoutAttributes, variantID, quantity, customAttributes }: IAddVariantToCartArgs,
): Promise<ICheckout> {
  const ensuredCheckoutID: string = await ensureCheckoutID({
    checkoutID,
    buildCheckoutAttributes,
  })

  const shopifyFormattedCustomAttributes = [
    { key: '_sourceURL', value: customAttributes._sourceURL },
    { key: '_ministryName', value: customAttributes._ministryName },
    { key: '_accountID', value: customAttributes._accountID },
  ]

  const lineItemsToAdd = [
    {
      variantId: variantID,
      quantity,
      customAttributes: shopifyFormattedCustomAttributes,
    },
  ]

  const checkout: ICheckout = await client.checkout.addLineItems(
    ensuredCheckoutID,
    lineItemsToAdd,
  )

  return checkout
}
