import { Stringable } from '../../../lib/types'
import { MoneyV2 } from './shopify_client'

interface IObjectWithTitle {
  title: string
  [other: string]: any
}

export function fullTitle(
  { product, variant, showVariant }:
    { product: IObjectWithTitle, variant: IObjectWithTitle, showVariant: boolean },
): string {
  if (showVariant) {
    return `${product.title} (${variant.title})`
  } else {
    return product.title
  }
}

interface IDetailPageUrlParams {
  prefix: string
  title: string
  productID: string
  variantID?: string | null
  accountID: Stringable
}
export function detailPageURL(
  { prefix, title, productID, variantID, accountID }: IDetailPageUrlParams,
): string {
  const idSegment = [productID, variantID]
    .filter((id) => id)
    .map((segment) => {
      if (segment.startsWith('gid://')) {
        // base64 encode the shopify IDs so we can pass them in the URL
        return btoa(segment)
      } else {
        return segment
      }
    })
    .join('/')
  return `${prefix}/p/${idSegment}?internalTitle=${title}&accountID=${accountID}`
}

export function formatAmount(amt: MoneyV2): string
export function formatAmount(amt: number, currencyCode: string): string
export function formatAmount(amt: number | MoneyV2, currencyCode?: string): string {
  if (typeof amt != 'number') {
    if (!currencyCode) { currencyCode = amt.currencyCode }
    amt = parseFloat(amt.amount)
  }

  if (Number.isInteger(amt)) {
    return `$${amt.toString()}`
  }
  return `$${amt.toFixed(2)}`
}