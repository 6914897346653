import * as React from 'react'
import { default as DocumentIcon } from '@material-ui/icons/InsertDriveFileOutlined'
import { default as VideoIcon } from '@material-ui/icons/LocalMoviesRounded'
import { default as ImageIcon } from '@material-ui/icons/PhotoOutlined'
import { default as AudioIcon } from '@material-ui/icons/VolumeUpRounded'
import { ViewAssetButton } from '../../resource-landing/buttons/viewAssetButton'
import { DownloadAssetButton } from '../../resource-landing/buttons/downloadAssetButton'
import { CopyAssetButton } from '../../resource-landing/buttons/copyAssetButton'
import { IResourceResultCompositeHit } from '../search_results'
import { Hit } from 'react-instantsearch/connectors'
import path from 'path'
import { present } from '../../../lib/utils/present'
import { IResource } from '../../../lib/contentful/generated'
import { Resolved } from '../../../lib/contentful'
import { ResourceActionMenu } from '../../resource-action-menu'

interface IProps {
  resource: Hit<IResourceResultCompositeHit> | Resolved<IResource>,
  isTyping: boolean,
  baseUrl?: string
}

const iconMap = {
  article: DocumentIcon,
  asset: ImageIcon,
  audio: AudioIcon,
  document: DocumentIcon,
  image: ImageIcon,
  video: VideoIcon,
}

export function ResourceHit({ resource, baseUrl, isTyping }: IProps) {
  const { id } = isHit(resource) ? resource['Resource'] : resource.sys
  const {
    title, icon, blurb, downloadAsset, downloadExternalAssetUrl, viewAsset, viewExternalAssetUrl, viewPage,
  } = isHit(resource) ? resource['Resource'] : resource.fields
  const Icon = getIcon(icon)

  const urlParams = new URLSearchParams(window.location.search)
  const resourceId = urlParams.get('resourceId')

  const isSharedResource = (resourceId && resourceId === id) && !isTyping

  const viewUrl = viewExternalAssetUrl || viewAsset?.fields?.file.url || viewPage?.fields?.slug
  const downloadUrl = path.join(baseUrl || window.location.pathname, `./resources/${id}`)

  const trackDownload = !viewUrl && ( present(downloadExternalAssetUrl) ||
    present(downloadAsset))

  const trackingProps = {
    'download': downloadAsset?.fields?.file?.fileName,
    'data-track-event': true,
    'data-event-category': 'Search',
    'data-event-label': downloadUrl,
    'data-event-action': 'View',
  }

  return (
    <div className={`resource-set__resource-wrapper ${isSharedResource ? 'shared' : ''}`}>
      <div className='resource-set__resource'>
        <div className='resource-set__resource-icon'>
          <Icon />
          <p className='overline'>{icon}</p>
        </div>
        <h3 className='resource-set__resource-title'>
          { icon === ('video' || 'audio') && viewUrl ? <ViewAssetButton resource={isHit(resource) ? resource['Resource'] : resource} icon={type => {
            return <span>{title}</span>
          }} /> : <a { ...(trackDownload ? trackingProps : null) }
            href={viewUrl || downloadExternalAssetUrl || downloadUrl}
            target='_blank'
            rel='noreferrer noopener'>
            {title}
          </a> }
        </h3>
        <div className='resource-set__resource-info'>
          <p className='resource-set__resource-blurb'>{blurb}</p>
          <ResourceActionMenu>
            <ViewAssetButton resource={isHit(resource) ? resource['Resource'] : resource} icon={type => {
              switch(type) {
                case 'video':
                case 'audio':
                  return <span>Play</span>
                default:
                  return <span>View</span>
              }
            }} />
            <DownloadAssetButton resource={isHit(resource) ? resource['Resource'] : resource} icon={<span>Download</span>} baseUrl={baseUrl} />
            <CopyAssetButton resource={isHit(resource) ? resource['Resource'] : resource} icon={(copied) => <span>{copied ? 'Link Copied!' : 'Copy Link'}</span>} baseUrl={baseUrl} />
          </ResourceActionMenu>
        </div>
      </div>
    </div>
  )
}

function getIcon(key: string) {
  return iconMap[key] || iconMap['document']
}

function isHit(resource: Hit<IResourceResultCompositeHit> | IResource): resource is Hit<IResourceResultCompositeHit> {
  return resource['Resource'] !== undefined
}
