import { client } from './shopify'
import { ICheckout } from './types'

interface IRemoveFromCartArgs {
  checkoutID: string
  lineItemIDS: string[]
}

export async function removeFromCart({ checkoutID, lineItemIDS }: IRemoveFromCartArgs): Promise<ICheckout> {
  const checkout: ICheckout = await client.checkout.removeLineItems(checkoutID, lineItemIDS)
  return checkout
}
