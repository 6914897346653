import * as React from 'react'

const escapeHTML = (text: string) => {
	const doc = new DOMParser().parseFromString(text, 'text/html')
	doc.body.textContent = text
	return doc.body.innerHTML
}

export default function safeLineBreak(text: string) {
	text = escapeHTML(text)
	text = text.replace(/&amp;(nbsp|vert|\#\d+);/g, (match, entity) => `&${entity};`)
             .replace(/&lt;br\/?&gt;/g, '<br/>')

	return React.createElement('span', {
		className: 'safe-line-break',
		dangerouslySetInnerHTML: {__html: text },
	})
}