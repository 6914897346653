import * as React from 'react'
import { isAsset, isEntry } from '../../lib/contentful'
import { IResource } from '../../lib/contentful/generated'
import { default as DocumentIcon } from '@material-ui/icons/InsertDriveFileOutlined'
import { default as VideoIcon } from '@material-ui/icons/LocalMoviesRounded'
import { default as ImageIcon } from '@material-ui/icons/PhotoOutlined'
import { default as AudioIcon } from '@material-ui/icons/VolumeUpRounded'
import { default as MoreIcon } from '@material-ui/icons/MoreHorizRounded'
import { ViewAssetButton } from './buttons/viewAssetButton'
import { DownloadAssetButton } from './buttons/downloadAssetButton'
import { CopyAssetButton } from './buttons/copyAssetButton'
import path from 'path'
import { present } from '../../lib/utils/present'
import { ResourceActionMenu } from '../resource-action-menu'

interface IProps {
  resource: IResource,

  baseUrl?: string
}

const iconMap = {
  article: DocumentIcon,
  asset: ImageIcon,
  audio: AudioIcon,
  document: DocumentIcon,
  image: ImageIcon,
  video: VideoIcon,
}

export function Resource({ resource, baseUrl }: IProps) {
  const {
    title, icon, blurb, downloadAsset, downloadExternalAssetUrl, viewAsset, viewExternalAssetUrl, viewPage
  } = resource.fields

  const viewUrl = viewExternalAssetUrl || isAsset(viewAsset) && viewAsset?.fields?.file.url || isEntry(viewPage) && viewPage?.fields?.slug
  const downloadUrl = path.join(baseUrl || window.location.pathname, `./resources/${resource.sys.id}`)

  const Icon = getIcon(icon)
  const [isSharedResource, setIsSharedResource] = React.useState(false)

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const resourceId = urlParams.get('resourceId')

    if(resourceId && resourceId === resource.sys.id){
      setIsSharedResource(true)
      document.getElementById(resourceId).scrollIntoView({behavior: 'smooth', block: 'center'})
    }
  }, [resource.sys.id])

  const trackDownload = !viewUrl && ( present(downloadExternalAssetUrl) ||
  present(downloadAsset))

  const trackingProps = {
    'download': isEntry(downloadAsset) && downloadAsset?.fields?.file?.fileName,
    'data-track-event': true,
    'data-event-category': 'Search',
    'data-event-label': downloadUrl,
    'data-event-action': 'View',
  }

  return (
    <div className={`resource-set__resource-wrapper ${isSharedResource ? 'shared' : ''}`}>
      <div id={resource.sys.id} className='resource-set__resource'>
        <div className='resource-set__resource-icon'>
          <Icon />
          <p className='overline'>{icon}</p>
        </div>
        <h3 className='resource-set__resource-title'>
        { (icon === 'video' || icon === 'audio' && viewUrl) ? <ViewAssetButton resource={resource} icon={type => {
            return <span>{title}</span>
          }} /> : <a { ...(trackDownload ? trackingProps : null) }
            href={viewUrl || downloadExternalAssetUrl || downloadUrl}
            target='_blank'
            rel='noreferrer noopener'>
            {title}
          </a> }
        </h3>
        <div className='resource-set__resource-info'>
          <p className='resource-set__resource-blurb'>{blurb}</p>
          <ResourceActionMenu>
            <ViewAssetButton resource={resource} icon={type => {
              switch(type) {
                case 'video':
                case 'audio':
                  return <span>Play</span>
                default:
                  return <span>View</span>
              }
            }} />
            <DownloadAssetButton resource={resource} icon={<span>Download</span>} baseUrl={baseUrl} />
            <CopyAssetButton resource={resource} icon={(copied) => <span>{copied ? 'Link Copied!' : 'Copy Link'}</span>} baseUrl={baseUrl} />
          </ResourceActionMenu>
        </div>
      </div>
    </div>
  )
}

function getIcon(key: string) {
  return iconMap[key] || iconMap['document']
}

