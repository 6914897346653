import * as React from 'react'
import { Lightbox } from 'react-modal-image'

import { default as ViewIcon } from '@material-ui/icons/VisibilityOutlined'

import MaterialButton from '../material-button'

interface IProps {
  buttonProps?: any
  imageUrl: string
  materialIcon?: JSX.Element
}

interface IState {
  isOpen: boolean
}

export class ModalImage extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      isOpen: false,
    }
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  public openModal(event: any): void {
    event.preventDefault()
    this.setState({ isOpen: true })
  }

  public closeModal(event: any): void {
    this.setState({ isOpen: false })
  }

  public render() {
    const { imageUrl } = this.props
    const buttonProps = this.props.buttonProps || {}
    return (
      <div style={{ display: 'inline' }}>
        {
          this.state.isOpen && (
            <Lightbox
              large={imageUrl}
              hideDownload={true}
              hideZoom={true}
              alt="Resource Image"
              onClose={this.closeModal}
            />
          )
        }
        <MaterialButton
          icon={this.props.materialIcon || <ViewIcon />}
          onClick={this.openModal} buttonText="View Image"
          { ...buttonProps }
        />
      </div>
    )
  }
}
