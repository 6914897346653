import * as React from 'react'
import safeLineBreak from '../safe-line-break/safe-line-break'

export interface ILessonMenu {
	menuName: string,
	set: {
		overline: string,
		text: string,
		href: string
	}[]
}

export const LessonMenu = ({ menuName, set }: ILessonMenu) => {
	const menuItems = set.map((item, index) => {
	let isExternal = true

	if(item.href && (item.href.startsWith('/') || window.location.hostname === new URL(item.href, window.location.href).hostname)) {
		isExternal = false
	}

		return (
			<a key={index} href={item.href} target={isExternal ? '_blank' : ''} rel="noreferrer">
				<div className='menu-item'>
					<span className='overline'>{item.overline}</span>
					<br />
					{safeLineBreak(item.text)}
				</div>
			</a>
		)})


	return (
		<div className='lesson-menu'>
			<h3 className='lesson-menu__header'>{menuName}</h3>

			<div className="menu-item__container">
				{menuItems}
			</div>
		</div>
	)
}
