import I18n from 'i18n-js'
import * as React from 'react'
import Modal from 'react-modal'
import WCC from 'wcc'
import { normalizeAndNotifyException } from '../../../lib/async-error-handler'
import { detailPageURL, formatAmount, fullTitle as fullProductTitle } from '../lib/product'
import * as Client from '../lib/shopify_client'

async function catchErrors<T>({
  fn,
  handleError,
}: {
  fn: () => Promise<T>,
  handleError: (e: Error) => void,
}): Promise<T | null> {
  try {
    return await fn()
  } catch (rawError) {
    const ex = normalizeAndNotifyException({ exception: rawError })
    handleError(ex)
    return null
  }
}

const ErrorDisplay = ({ error }: { error?: Error }) => (
  <>
    {error && (
      <div className="error">
        An error occurred loading your cart. Please try refreshing the page.
      </div>
    )}
  </>
)

const EmptyCart = ({ loading, error }: { loading: boolean, error: Error }) => (
  <div className="row">
    <ErrorDisplay error={error} />
    <div className="column medium-12 text-center">
      {loading ? 'Loading. Please wait...' : 'Empty Cart'}
    </div>
  </div>
)

const LineItem = ({
  lineItem,
  updateItemQuantity,
  removeItem,
}: {
  lineItem: Client.ICheckoutLineItem,
  updateItemQuantity: (args: {
    lineItemID: string,
    quantity: number,
  }) => Promise<void>,
  removeItem: (args: { lineItemID: string }) => Promise<void>,
}) => {
  const { title, variant, quantity } = lineItem
  const { price } = variant
  const image: Client.IImage = variant.image || Client.DEFAULT_IMAGE
  const [quantityField, setQuantityField] = React.useState<number>(quantity)
  let quantitySaveButton: JSX.Element = null

  const customAttributes: Client.IMappedCustomAttributes = {}
  lineItem.customAttributes.forEach((attr) => {
    customAttributes[attr.key] = attr.value
  })

  const productURL = detailPageURL({
    prefix: customAttributes._sourceURL,
    title,
    productID: variant.product.id,
    variantID: variant.id,
    accountID: customAttributes._accountID,
  })

  const handleSaveLineItemQuantity = React.useCallback(async () => {
    await updateItemQuantity({
      lineItemID: lineItem.id,
      quantity: quantityField,
    })
  }, [updateItemQuantity, lineItem, quantityField])

  const handleRemoveLineItem = React.useCallback(async () => {
    await removeItem({
      lineItemID: lineItem.id,
    })
  }, [removeItem, lineItem])

  if (quantityField != quantity) {
    quantitySaveButton = (
      <button className="product-button" onClick={handleSaveLineItemQuantity}>
        Update
      </button>
    )
  }

  return (
    <div className="row">
      <div className="column medium-12">
        <div className="product-card">
          <div className="product-card__image">
            <img src={image.src} alt={image.altText} />
          </div>
          <div className="product-card__content">
            <h4 className="product-card__ministry-name">
              <a href={customAttributes._sourceURL}>
                {customAttributes._ministryName}
              </a>
            </h4>
            <h3 className="product-card__title">
              <a href={productURL}>
                {fullProductTitle({
                  product: lineItem,
                  variant,
                  showVariant: false,
                })}
              </a>
            </h3>
            <span className="product-card__asset-counter">
              <span className="label">Qty:</span>
              <input
                type="number"
                pattern="[0-9]*"
                onChange={(e) => setQuantityField(e.target.valueAsNumber)}
                onFocus={(e) => e.target.select()}
                value={quantityField}
              />
              {quantitySaveButton}
            </span>
            <div className="product-card__actions">
              <button className="product-button" onClick={handleRemoveLineItem}>
                Remove From Cart
              </button>
              <button className="product-button">
                Price <span className="product-button__price">{formatAmount(price)}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const GuestModal = ({
  guestURL,
  signInURL,
}: {
  guestURL: string,
  signInURL: string,
}) => {
  const [continueAsGuest, setContinueAsGuest] = React.useState<boolean>(true)
  const handleContinue = React.useCallback(() => {
    window.location.href = continueAsGuest ? guestURL : signInURL
  }, [guestURL, signInURL, continueAsGuest])

  return (
    <>
      <h2 className="title">Continue As Guest?</h2>
      <div className="form-check mt-4">
        <input
          id="guest-modal-yes"
          type="radio"
          className="form-check-input"
          checked={continueAsGuest}
          onChange={() => setContinueAsGuest(true)}
        />
        <label htmlFor="guest-modal-yes" className="form-check-label">
          Check out as a guest
        </label>
      </div>
      <div className="form-check mt-2">
        <input
          id="guest-modal-no"
          type="radio"
          className="form-check-input"
          checked={!continueAsGuest}
          onChange={() => setContinueAsGuest(false)}
        />
        <label htmlFor="guest-modal-no" className="form-check-label">
          Use my Watermark Resources account
        </label>
      </div>
      <a className="mt-3 button large primary" onClick={handleContinue}>
        Continue
      </a>
    </>
  )
}

const CartSummary = ({
  checkout,
  beforeCheckout,
}: {
  checkout: Client.ICheckout,
  beforeCheckout: () => Promise<void>,
}) => {
  const { subtotalPrice, lineItems, webUrl: webURL } = checkout
  const [modalOpen, setModalOpen] = React.useState<boolean>(false)

  const itemCount = lineItems.reduce((sum, item) => {
    return sum + item.quantity
  }, 0)

  const itemCountText = `${itemCount} item${itemCount == 1 ? '' : 's'}`

  const handleCheckout = React.useCallback(async () => {
    if (WCC.currentUser) {
      await beforeCheckout()
      window.location.href = webURL
    } else {
      setModalOpen(true)
      await beforeCheckout()
    }
  }, [webURL, beforeCheckout])

  return (
    <div className="row">
      <div className="column medium-12 totals">
        <table>
          <tbody>
            <tr>
              <th>Subtotal ({itemCountText})</th>
              <td>{formatAmount(subtotalPrice)}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="column medium-12 actions">
        <a className="button large primary" onClick={handleCheckout}>
          Checkout
        </a>
        <p>{I18n.t('cart.shipping_details')}</p>
      </div>
      <Modal
        isOpen={modalOpen}
        onRequestClose={() => setModalOpen(false)}
        className="ReactModal__WMRContent"
        htmlOpenClassName="ReactModal__HTML"
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        contentLabel="Continue As Guest?"
        ariaHideApp={false}
      >
        <GuestModal guestURL={webURL} signInURL={'/users/sign_in'} />
      </Modal>
    </div>
  )
}

interface IProps {
  checkoutID?: string
  b2bQuantityThreshold: number
  buildCheckoutAttributes: Client.IBuildCheckoutAttributes
  removeFromCart: (args: {
    checkoutID: string,
    lineItemIDS: string[],
  }) => Promise<Client.ICheckout>
  updateItemQuantity: (args: {
    checkoutID: string,
    lineItemID: string,
    quantity: number,
  }) => Promise<Client.ICheckout>
}

const Cart: React.FunctionComponent<IProps> = ({
  checkoutID,
  b2bQuantityThreshold,
  buildCheckoutAttributes,
  removeFromCart = Client.removeFromCart,
  updateItemQuantity = Client.updateItemQuantity,
}) => {
  const [checkout, setCheckout] = React.useState<Client.ICheckout>(null)
  const [loading, setLoading] = React.useState<boolean>(!!checkoutID)
  const [error, setError] = React.useState<Error>()

  React.useEffect(() => {
    async function loadCheckout() {
      if (!checkoutID) {
        return
      }
      const newCheckout = await catchErrors<Client.ICheckout>({
        fn: async () => Client.fetchCheckout(checkoutID),
        handleError: setError,
      })
      setCheckout(newCheckout)
      setLoading(false)
    }

    void loadCheckout()
  }, [checkoutID])

  const handleUpdateItemQuantity = React.useCallback(
    async ({
      lineItemID,
      quantity,
    }: {
      lineItemID: string,
      quantity: number,
    }) => {
      setLoading(true)
      const newCheckout = await updateItemQuantity({
        checkoutID,
        lineItemID,
        quantity,
      })
      setCheckout(newCheckout)
      setLoading(false)
    },
    [checkoutID, updateItemQuantity, setCheckout, setLoading],
  )

  const prepareCheckout = React.useCallback(async () => {
    const newCheckout = await Client.updateCheckout({
      checkout,
      attributes: {
        email: WCC.currentUser ? WCC.currentUser.email : null,
        shippingAddress: buildCheckoutAttributes.shippingAddress,
        b2bQuantityThreshold,
      },
    })

    setCheckout(newCheckout)
  }, [checkout, b2bQuantityThreshold, buildCheckoutAttributes])

  const handleRemoveItem = React.useCallback(
    async ({ lineItemID }: { lineItemID: string }) => {
      setLoading(true)
      const newCheckout = await removeFromCart({
        checkoutID,
        lineItemIDS: [lineItemID],
      })
      setCheckout(newCheckout)
      setLoading(false)
    },
    [checkoutID, removeFromCart, setCheckout, setLoading],
  )

  if (checkout && checkout.lineItems.length) {
    return (
      <div className={`cart ${loading ? 'loading' : ''}`}>
        <ErrorDisplay error={error} />
        {checkout.lineItems.map((item) => (
          <LineItem
            key={item.id}
            lineItem={item}
            updateItemQuantity={handleUpdateItemQuantity}
            removeItem={handleRemoveItem}
          />
        ))}
        <CartSummary checkout={checkout} beforeCheckout={prepareCheckout} />
      </div>
    )
  } else {
    return <EmptyCart loading={loading} error={error} />
  }
}

export { Cart }
