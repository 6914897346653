import * as React from 'react'
import { IMinistry } from '../../lib/contentful/generated'
import { AlgoliaSearch, HiddenFacets } from '@watermarkchurch/react-instantsearch-components'
import { SearchBox } from 'react-instantsearch/dom'
import SearchResults from './search_results'
import { connectNumericMenu } from 'react-instantsearch/connectors'
import WCC from 'wcc'

interface IProps {
  domain_object_id: string,
  indexName: string,
  access: {
    domainObject: IMinistry,
    securityLevel: number,
  }
}

const SecurityLevels = WCC.securityLevels.map((securityLevel) => ({
  label: securityLevel.name,
  start: 0,
  end: securityLevel.id,
}))

const VirtualNumericMenu = connectNumericMenu(() => null)

const LessonResourceSearch = (props: IProps ) => {
  const [isTyping, setIsTyping] = React.useState(false)

  return (
    <AlgoliaSearch
      indexName={props.indexName}
      widgetName='resource-search'
      storeStateInQuery >
      <HiddenFacets hiddenFacets={{ 'domain_object_id': props.domain_object_id }} />
      <VirtualNumericMenu
        items={SecurityLevels}
        attribute="security_level.id"
        defaultRefinement={`0:${props.access.securityLevel}`} />
      <SearchBox translations={{ placeholder: 'Search resources and FAQs'}} onChange={() => {
        setIsTyping(true)
        const urlParams = new URLSearchParams(window.location.search)

        if(urlParams.get('resourceId')) {
          const url = new URL(window.location.href)
          url.searchParams.delete('resourceId')
          window.history.replaceState({}, '', url.toString())
        }
        }}  />
      <SearchResults isTyping={isTyping} />
    </AlgoliaSearch>
  )
}

//http://wm-resources.wcc/ministries/reengage/leader-resources/search?query=small%20group&resourceId=5fxElwrGooEO2WMI82o6Q8
//
//http://wm-resources.wcc/ministries/reengage/leader-resources/search?query=
export default LessonResourceSearch