import * as React from 'react'
import { connectHits, Hit } from 'react-instantsearch-core'
import IAlgoliaMessage from '../../lib/models/algolia-message'
import { MediaGalleryHitComponent } from '../media-gallery-wrapper'

interface IMessagesProps {
  hits: Array<Hit<IAlgoliaMessage>>
}

class MessageList extends React.Component<IMessagesProps> {
  public render() {
    const { hits } = this.props

    return <div className="row">
      {hits.map((hit) => <MediaGalleryHitComponent
        key={`message-${hit.objectID}`} hit={hit}
        columnClasses={['col-12', 'col-sm-6']}
      />)}
    </div>
  }
}

export const ConnectedMessageList = connectHits(MessageList)
