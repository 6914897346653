import * as React from 'react'

import { connectNumericMenu, connectRefinementList } from 'react-instantsearch-core'
import { SearchBox } from 'react-instantsearch/dom'

import WCC from 'wcc'
import { IMinistry, IResourceTree } from '../../lib/contentful/generated'
import {AlgoliaSearch} from '@watermarkchurch/react-instantsearch-components'

import { DomainStyles } from './domain-styles'
import ResourceSearchResults from './search_results'
import { ResourceTree } from './tree'

const widgetName = 'resource-tree'

interface IProps {
  tree: IResourceTree,
  domain_object_id: string,
  indexName: string,
  access: {
    domainObject: IMinistry,
    securityLevel: number,
  }
}

// https://community.algolia.com/react-instantsearch/guide/Virtual_widgets.html
const VirtualNumericMenu = connectNumericMenu(() => null)
const VirtualRefinementList = connectRefinementList(() => null)

// transform security level DB to Algolia's menu representation
const SecurityLevels = WCC.securityLevels.map((securityLevel) => ({
  label: securityLevel.name,
  start: 0,
  end: securityLevel.id,
}))

/**
 * The root component rendered on the Leader Resources page
 * via Section: Resource List
 */
export class SearchableTree extends React.Component<IProps> {
  public render() {
    const { tree, domain_object_id, indexName, access } = this.props
    const domainColor = access.domainObject.fields.backgroundColor

    return (
      <AlgoliaSearch
        indexName={indexName}
        widgetName={widgetName}
        storeStateInQuery={true}
      >
        <div className={widgetName}>
          <VirtualRefinementList attribute="domainObject.sys.id"
            defaultRefinement={[domain_object_id]}
          />
          <VirtualNumericMenu attribute="securityLevel.id"
            items={SecurityLevels}
            defaultRefinement={`0:${access.securityLevel}`} />
          <div className={`${widgetName}__search-box`}>
            <SearchBox translations={{ placeholder: 'Search' }} />
          </div>
          <DomainStyles domainColor={domainColor}>
            <div className={`${widgetName}__search-results`}>
              <ResourceSearchResults />
            </div>
            <ResourceTree tree={tree} />
          </DomainStyles>
        </div>
      </AlgoliaSearch>
    )
  }
}
