import WCC from 'wcc'
const globalConstants = WCC && WCC.WMR_CONSTANTS ? WCC.WMR_CONSTANTS : {}

export async function shopifyGql<TData extends Record<string, any> = Record<string, any>>(
  query: string, variables?: Record<string, any>
) {
  const resp = await fetch(`https://${globalConstants.SHOPIFY_DOMAIN}/api/2023-10/graphql`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-Shopify-Storefront-Access-Token': globalConstants.SHOPIFY_STOREFRONT_API_KEY
    },
    body: JSON.stringify({
      query,
      variables
    })
  })
  if (resp.status >= 300) { throw new Error(`unexpected status code ${resp.status}`)}

  return await resp.json() as { data: TData }
}
