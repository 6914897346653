import * as React from 'react'

import { default as CloseIcon } from '@material-ui/icons/CloseRounded'
import { default as ShareIcon } from '@material-ui/icons/Share'

interface IShareProps {
  url: string
}
interface IShareState {
  expanded: boolean
  copied: boolean
}

const hiddenInput = (val) => {
  const el = document.createElement('input')
  el.value = val
  el.setAttribute('readonly', '')
  el.style.position = 'absolute'
  el.style.left = '-9999px'

  return el
}

export class SharePopover extends React.Component<IShareProps, IShareState> {
  private resetTimeout?: NodeJS.Timeout
  private input: HTMLInputElement
  private componentID: number
  private containerRef: React.RefObject<HTMLDivElement>

  constructor(props) {
    super(props)

    this.expand = this.expand.bind(this)
    this.copyToClipboard = this.copyToClipboard.bind(this)
    this.reset = this.reset.bind(this)
    this.input = hiddenInput(this.props.url)

    this.state = { expanded: false, copied: false }

    this.componentID = Math.floor((Math.random() * 1000000000))
    this.containerRef = React.createRef()
  }

  public componentWillUnmount() {
    this.unbindClick()
    clearTimeout(this.resetTimeout)
  }

  public render() {
    const { copied, expanded } = this.state

    return <div className="share-button" ref={this.containerRef}>
      <a className="share-button__expander" onClick={this.expand}>
        <ShareIcon />
      </a>

      <div className={`share-button__dialog ${expanded && 'expanded'}`}>
        { copied &&
            <a className="copy-link" onClick={this.reset}>
              <ShareIcon />
              <span>Link copied</span>
            </a> }

        { !copied &&
            <a className="copy-link" onClick={this.copyToClipboard}>
              <ShareIcon />
              <span>Copy Link</span>
            </a> }

        <a onClick={this.reset}>
          <CloseIcon />
        </a>
      </div>
    </div>
  }

  private copyToClipboard(e) {
    e.stopPropagation()

    // Add input to document
    document.body.appendChild(this.input)

    // select the input value
    this.input.select()
    this.input.setSelectionRange(0, 99999) /*For mobile devices*/

    // tell the system to add to clipboard
    document.execCommand('copy')

    // remove input from document
    document.body.removeChild(this.input)

    this.setState({ copied: true })
    this.resetTimeout = setTimeout(this.reset, 3000)
  }

  private expand(e) {
    e.stopPropagation()
    this.setState({ expanded: true })
    this.bindClick()
  }

  private reset(e?) {
    if (e) { e.stopPropagation() }

    this.setState({ copied: false, expanded: false })
    this.unbindClick()
    clearTimeout(this.resetTimeout)
  }

  private bindClick() {
    $(document).on(`click.share-${this.componentID}`, (e) => {
      if (!this.containerRef.current.contains(e.target)) {
        this.reset()
      }
    })
  }

  private unbindClick() {
    $(document).off(`click.share-${this.componentID}`)
  }
}
