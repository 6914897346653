import * as React from 'react'
import {
  connectStateResults,
  StateResultsProvided,
} from 'react-instantsearch-core'
import { Stats } from 'react-instantsearch-dom'
import { ConnectedMessageList } from './message-list'
import { ConnectedSearchReset } from './search-reset'

const hasSearchQuery = (searchState: StateResultsProvided['searchState']): boolean => (
  searchState.query ? true : false
)

const hasSelectedFacet = (searchState: StateResultsProvided['searchState']): boolean => {
  if (!searchState.refinementList) { return false }
  if (searchState.refinementList['playlists.title'] && searchState.refinementList['playlists.title'].length > 0) {
    return true
  } else if (searchState.refinementList['speakers.name'] && searchState.refinementList['speakers.name'].length > 0) {
    return true
  } else {
    return false
  }
}

const hasSearchState = (searchState?: StateResultsProvided['searchState']): boolean => {
  if (!searchState) { return false }
  return hasSearchQuery(searchState) || hasSelectedFacet(searchState)
}

const hasSearchResults = (searchResults?: StateResultsProvided['searchResults']): boolean => {
  if (!searchResults) { return false }
  return searchResults.nbHits > 0
}

class SearchResults extends React.PureComponent<StateResultsProvided> {
  public render() {
    const { searchState, searchResults } = this.props

    if (!hasSearchState(searchState)) { return null }
    if (!hasSearchResults(searchResults)) { return <ConnectedSearchReset /> }

    return <>
      <div className="row">
        <div className="col-12">
          <div className="ais-Stats">
            <span className="ais-Stats-text">
              <Stats
                translations={{
                  stats(nbHits) {
                    return `${nbHits} results`
                  },
                }}
              />
            </span>
          </div>
        </div>
      </div>
      <ConnectedMessageList />
    </>
  }
}

const ConnectedSearchResults = connectStateResults(SearchResults)

export default ConnectedSearchResults
