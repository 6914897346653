import * as React from 'react'
import { connectRefinementList, Highlight } from 'react-instantsearch-dom'

const RefinementList = ({
  items,
  isFromSearch,
  refine,
  createURL,
}) => (
    <>
      {items.map((item) => (
        <a
          href={createURL(item.value)}
          key={item.label}
          onClick={(event) => {
            event.preventDefault()
            refine(item.value)
          }}
        >
          <button className="dropdown-item">
            <span
              className="refinement"
              style={{ fontWeight: item.isRefined ? 'bold' : 'unset' }}
            >
              {isFromSearch ? (
                <Highlight attribute="label" hit={item} />
              ) : (
                  item.label
                )}{' '}
              {item.isRefined && <i className="icon ion-checkmark"></i>}
            </span>
          </button>
        </a>
      ))}
    </>
  )

const MessageRefinementList = connectRefinementList(RefinementList)

export default MessageRefinementList
