import {
  GalleryBlockHit,
  IMediaApiMessage,
  IMediaGalleryProps,
  MediaGallery,
} from '@watermarkchurch/react-instantsearch-components'
import * as React from 'react'

import IAlgoliaMessage from '../../lib/models/algolia-message'

export default class MediaGalleryWrapper extends React.Component<IMediaGalleryProps> {
  public render() {
    let headerComponent: JSX.Element | undefined
    if (this.props.header) {
      headerComponent = <div className="row">
        <div className="col-12 title-box">
          <h4>{this.props.header}</h4>
          <hr className="title-box__hr" />
        </div>
      </div>
    }

    return <MediaGallery {...this.props}
      header={headerComponent}
      HitComponent={MediaGalleryHitComponent} />
  }
}

interface IHitComponentProps {
  hit: IAlgoliaMessage | IMediaApiMessage
  columnClasses?: string[]
}

const tagsToDisplay = ['Breakout', 'Workshop']

const findTag = (tags: string[] | null) => {
  if (!tags) { return }

  return tagsToDisplay.find((ttd) => tags.find((t) => (new RegExp(ttd, 'i')).test(t)))
}

const renderBadge = (message: IAlgoliaMessage | IMediaApiMessage) => {
  const tag = findTag((message.tags as any).map((t) => t.name)) ||
    findTag(message.topics as string[])

  if (tag) {
    return <span className={`badge ${tag.toLowerCase()}`}>{tag}</span>
  }
}

const MediaGalleryHitComponent = (props: IHitComponentProps) => {
  const message = props.hit
  const id = 'objectID' in message ? message.objectID : message.id

  const hasVideo = message.embeds && message.embeds.video &&
    message.embeds.video.available !== false
  const cta = hasVideo ? 'Watch' : 'Listen'

  const loc = location.toString().replace(/\/m\/.+/, '')

  return <GalleryBlockHit {...props} hit={message}
            href={`${loc}/m/${id}-${message.slug}`}
          >
      <span className="text-cta">{cta}</span>
    </GalleryBlockHit>
}

export { MediaGalleryHitComponent }
