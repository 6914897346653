import * as React from 'react'
import { SearchBox } from 'react-instantsearch-dom'
import { FacetFilter } from './facet-filter'

interface IProps {
  facetFilters?: string | string[]
  translations: any
}

class SearchBar extends React.PureComponent<IProps> {
  public render() {
    const { facetFilters } = this.props

    const filters: string[] = typeof facetFilters === 'string' ? [facetFilters] : facetFilters || []

    return <div className="row">
      <div className="col-12 col-md-6 widget-media-search__search-box">
        <SearchBox translations={{ placeholder: 'Search...' }}/>
        <i className="search-icon"></i>
      </div>

      { filters.map((filter) =>
        <div className="col widget-media-search__filter" key={`filter-${filter}`}>
          <FacetFilter attribute={filter} name={ this.props.translations[filter] } />
        </div>,
      )}
    </div>
  }
}

export default SearchBar
