import React, { useState, useEffect, useRef } from "react"
import { downloadResources } from "../lib/utils/download_resources"
import { default as MoreIcon } from '@material-ui/icons/MoreHorizRounded'

export const ResourceActionMenu = ({ children }) => {
  const [showActions, setShowActions] = useState(false)
  const containerRef = useRef(null)

  const toggleShowActions = (event) => {
    // Only hide modal when the click is outside the modal
    setShowActions(containerRef.current && containerRef.current.contains(event.target))
  }

  useEffect(() => {
    document.addEventListener('click', toggleShowActions)
    return () => {
      document.removeEventListener('click', toggleShowActions)
    }
  }, [])

  return (
    <div ref={containerRef} onClick={toggleShowActions} className="resource-action-menu">
      <div className={`resource-action-menu__dropdown ${showActions ? 'show' : ''}`}>
        {children}
      </div>
      <MoreIcon />
    </div>
  )
}
