import { shopifyGql } from './graphql'
import { MoneyV2 } from './types'

export async function fetchProduct(
  { productID }: { productID: string },
) {
  const body = await shopifyGql<IFetchProductData>(
    fetchProductQuery,
    { id: productID }
  )

  return transformProduct(body.data.node)
}

export async function fetchProducts(
  { collectionID }: { collectionID: string },
) {
  const body = await shopifyGql<IFetchProductsData>(
    fetchProductsQuery,
    { id: collectionID }
  )
  
  return body.data.node.products.edges.map((p) => {
    return transformProduct(p.node)
  })
}

function transformProduct(p: IProductFragment) {
  return {
    ...p,
    variants: p.variants.edges.map((v) => transformVariant(v.node))
  }
}

function transformVariant(v: IVariantFragment) {
  return {
    ...v,

    // currentlyNotInStock: undefined

    // if inventory tracking is turned off, then
    // quantityAvailable from the Shopify API is meaningless.
    // Unfortunately we can't detect this condition (except when
    // it's obvious, e.g. when the number is < 0)
    quantityAvailable:
      typeof(v.quantityAvailable) == 'number' && v.quantityAvailable >= 0 ?
        v.quantityAvailable : undefined,
    /**
     * Client-side logic, intentionally a different key than the gql API field
     * "currentlyNotInStock".  That field appears to not follow inventory tracking
     * so we track quantityAvailable instead.
     */
    isOutOfStock:
      typeof(v.quantityAvailable) == 'number' && v.quantityAvailable === 0
  }
}

const variantFragment = `
fragment VariantFragment on ProductVariant {
  id
  title
  price {
    amount
    currencyCode
  }
  quantityAvailable
  image {
    src: originalSrc
    altText
  }
}`

interface IVariantFragment {
  id: string
  title: string
  price: MoneyV2
  quantityAvailable?: number
  image: {
    src: string
    altText: string
  }
}

const productFragment = `
${variantFragment}

fragment ProductFragment on Product {
  id
  title
  descriptionHtml
  variants(first: 250) {
    edges {
      node {
        ...VariantFragment
      }
    }
  }
}`

export interface IProductFragment {
  id: string
  title: string
  descriptionHtml: string
  variants: {
    edges: Array<{ node: IVariantFragment }>
  }
}

const fetchProductQuery = `
${productFragment}

query ($id: ID!) {
  node(id: $id) {
    __typename
    ...ProductFragment
  }
}
`
export interface IFetchProductData {
  node: {
    ___typename: 'Product',
  } & IProductFragment
}

const fetchProductsQuery = `
${productFragment}

query ($id: ID!) {
  node(id: $id) {
    __typename
    ... on Collection {
      id
      products(first: 250) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            ...ProductFragment
          }
        }
      }
    }
  }
}
`

export interface IFetchProductsData {
  node: {
    ___typename: 'Collection',
    id: string
    products: {
      pageInfo: {
        hasNextPage: boolean,
        hasPreviousPage: boolean
      }
      edges: Array<{
        node: IProductFragment
      }>
    }
  }
}