import { addVariantToCart } from './add_variant_to_cart'
import { createCheckout } from './create_checkout'
import { fetchCheckout } from './fetch_checkout'
import { fetchProduct, fetchProducts } from './fetch_products'
import { removeFromCart } from './remove_from_cart'
import * as Types from './types'
import { updateCheckout } from './update_checkout'
import { updateItemQuantity } from './update_item_quantity'

export * from './types'

export const DEFAULT_IMAGE: Types.IImage = {
  src: '',
  altText: 'Missing image',
}

export {
  fetchCheckout,
  createCheckout,
  updateCheckout,
  fetchProduct,
  fetchProducts,
  addVariantToCart,
  updateItemQuantity,
  removeFromCart,
}
