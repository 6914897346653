import * as React from 'react'

import { Resolved } from '../../lib/contentful'
import {
  IResourceSet,
  IResourceSetFields,
  IResourceTree,
  IResourceTreeFields,
  isResourceTree,
  ResourceTreeBranch,
} from '../../lib/contentful/generated'
import { expectResolved, isResolved } from '../../lib/contentful/utils'

import { Branch } from './branch'
import { Leaf } from './leaf'

export const keyGen = (part: ResourceTreeBranch | Resolved<ResourceTreeBranch>) =>
  `${part.sys.contentType.sys.id}-${part.sys.id}-${isResolved<IResourceSetFields | IResourceTreeFields>(part, 2) && 'resolved'}`

export const renderLabel = (part: ResourceTreeBranch) =>
  // Link exists for GA tag manager event tracking
  <>
    <a
      className="resource-card-link"
      data-id={part.sys.id}
      data-internaltitle={part.fields.internalTitle}
    >
      <span className="visually-hidden">{part.fields.title}</span>
    </a>
    {part.fields.ionicon && <i className={`resource-card__icon ${part.fields.ionicon}`}></i>}
    <h3 className="resource-card__title">{part.fields.title}</h3>
    {part.fields.blurb && <p className="resource-card__text">{part.fields.blurb}</p> }
  </>

class ResourceTreeCache {
  private cache: { [id: string]: Resolved<ResourceTreeBranch> } = {}

  public get(id: string) {
    return this.cache[id]
  }

  public put(part: Resolved<ResourceTreeBranch>) {
    this.cache[part.sys.id] = expectResolved<IResourceSetFields | IResourceTreeFields>(part, 2)
  }

  public clear() {
    delete this.cache
    this.cache = {}
  }
}

export const cache = new ResourceTreeCache()

export const renderPart = (part: ResourceTreeBranch | Resolved<ResourceTreeBranch>) => {
  part = cache.get(part.sys.id) || part

  return isResourceTree(part) ?
    <Branch key={keyGen(part)} {...part} /> :
    <Leaf key={keyGen(part)} {...part} />
}

export const branchURL = (id: string) => {
  const url = new URL(location.href)
  url.hash = id
  url.search = ''

  return url.toString()
}
