import * as React from 'react'
import { connectStateResults, StateResultsProvided } from 'react-instantsearch-core'
import { Stats } from 'react-instantsearch-dom'
import Resource, { IResourceResult } from './resource'

const ResourceSearchResults = (props: StateResultsProvided<IResourceResult>) => {
  const {searchState, searchResults, error} = props
  if (error) {
    return <div>
        <h1>An error occurred while searching!</h1>
        <span className="error">{error.message}</span>
      </div>
  }

  if (!searchState || !searchState.query || searchState.query.length <= 0) {
    return null
  }

  return (<div>
      <Stats translations={ { stats: (num, _) => `${num} matching resources` } } />
      <ul>
        {searchResults && searchResults.hits.map((hit) =>
          <Resource key={hit.objectID} id={hit.objectID} resource={hit} query={ searchState.query } />,
        )}
      </ul>
    </div>)
}

export default connectStateResults(ResourceSearchResults)
