import * as React from 'react'

interface IMaterialButtonProps {
  buttonText?: string
  icon: JSX.Element
}

export default class MaterialButton extends React.Component<
  IMaterialButtonProps & React.HTMLProps<HTMLAnchorElement>
> {
  public static defaultProps = {
    buttonText: 'View',
  }

  public render() {
    const { icon, buttonText, ...props } = this.props

    return (
      <a {...props}>
        { icon }
        <span className="visually-hidden">{this.props.buttonText}</span>
      </a>
    )
  }
}
