import ModalVideo from "react-modal-video"

export class WCCModalVideo extends ModalVideo {
  getWMUrl(watermark, videoId) {
    const query = this.getQueryString(watermark)
    return '//media.watermark.org/embeds/' + videoId + '/video' + '?' + query
  }

  getVideoUrl(opt, videoId) {
    if (opt.channel === 'youtube') {
      return this.getYoutubeUrl(opt.youtube, videoId)
    } else if (opt.channel === 'vimeo') {
      return this.getVimeoUrl(opt.vimeo, videoId)
    } else if (opt.channel === 'youku') {
      return this.getYoukuUrl(opt.youku, videoId)
    } else if (opt.channel === 'watermark') {
      return this.getWMUrl(opt.watermark, videoId)
    } else if (opt.channel === 'custom') {
      return opt.url
    }
  }
}
