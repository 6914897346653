import * as React from 'react'

interface IButtonProps {
  linkText?: string
  openModal(event: any): void
}

export class Button extends React.Component<IButtonProps & React.HTMLProps<HTMLAnchorElement>> {
  public render() {
    const { openModal, linkText, ...props } = this.props

    return (
      <a { ...props } className="quote-card__button" href="#" onClick={openModal}>
        {linkText} <i className="ion ion-play" />
      </a>
    )
  }
}
