import * as React from 'react'
import { default as DocumentIcon } from '@material-ui/icons/InsertDriveFileOutlined'
import { IResourceResultCompositeHit } from '../search_results'
import { Hit } from 'react-instantsearch/connectors'
import { IPage, IPageMetadata } from '../../../lib/contentful/generated'
import { Resolved } from '../../../lib/contentful'
import { ResourceActionMenu } from '../../resource-action-menu'

export interface IPageResult {
  id: string,
  title: string,
  slug: string,
  isResourceLandingPage: boolean,
  theme: {
    id: number,
    key: string,
    title: string,
    view_path: string,
  },
  metaDescription: string,
}



export function PageHit({ resource, isTyping }: { resource: Hit<IResourceResultCompositeHit> | Resolved<IPage>, isTyping: boolean }) {
  const meta = !isHit(resource) && resource.fields.meta as IPageMetadata
  const { metaDescription } = isHit(resource) ? resource['Page'] : meta.fields
  const { title, slug } = isHit(resource) ? resource['Page'] : resource.fields
  const { id } = isHit(resource) ? resource['Page'] : resource.sys

  const urlParams = new URLSearchParams(window.location.search)
  const resourceId = urlParams.get('resourceId')

  const isSharedResource = (resourceId && resourceId === id) && !isTyping

  return (
    <div className={`resource-set__resource-wrapper ${isSharedResource ? 'shared' : ''}`}>
      <div className='resource-set__resource'>
        <div className='resource-set__resource-icon'>
          <DocumentIcon />
          <p className='overline'>Lesson</p>
        </div>
        <h3 className='resource-set__resource-title'><a href={slug}>{title}</a></h3>
        <div className='resource-set__resource-info'>
          <p className='resource-set__resource-blurb'>{metaDescription}</p>
          <ResourceActionMenu>
            <a style={{cursor: 'pointer'}} onClick={() => window.open(slug, '_blank')}><span>View</span></a>
          </ResourceActionMenu>
        </div>
      </div>
    </div>
  )
}

function isHit(resource: Hit<IResourceResultCompositeHit> | IPage): resource is Hit<IResourceResultCompositeHit> {
  return resource['Page'] !== undefined
}
