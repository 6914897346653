import JSZip from 'jszip'
import { Semaphore } from 'async-toolbox'
import { IResource } from '../contentful/generated'
import { IResourceResultCompositeHit } from '../../components/resource-search/search_results'
import { Resolved, isAsset, isEntry } from '../contentful'
import path from 'path'

const excludedFileTypes = /\.(mp4|mov|avi|wmv|flv|mkv|webm|mp3|wav|aac|ogg)$/i
const isExcluded = (resource: IResource | IResourceResultCompositeHit) => {
  const { downloadExternalAssetUrl, downloadAsset } = isEntry(resource)
  ? resource.fields
  : resource['Resource']

  if (!downloadExternalAssetUrl && !downloadAsset) return true
  
  const externalAssetForbidden = excludedFileTypes.test(downloadExternalAssetUrl)
  const assetForbidden = isAsset(downloadAsset) && excludedFileTypes.test(downloadAsset.fields.file.url)
  
  return externalAssetForbidden || assetForbidden
}

const filterResources = (resource: IResource | IResourceResultCompositeHit) => {
  if (resource['Page'] || isExcluded(resource)) return false
  
  const { downloadAsset, downloadExternalAssetUrl } = isEntry(resource)
    ? resource.fields
    : resource['Resource']
  
  return downloadExternalAssetUrl || downloadAsset
}

const mappedResources = (resource: Resolved<IResource> | IResourceResultCompositeHit) => {
  const id = isEntry(resource) ? resource.sys.id : resource['Resource'].id
  const downloadUrl = path.join(window.location.origin, `/resources/${id}`)

  return downloadUrl
}

const fetchSemaphore = new Semaphore({ tokens: 5 })

export const downloadResources = async (resources: Array<IResource | IResourceResultCompositeHit>, zipName = 'resources' ) => {
  const safeZipName = zipName.trim().replace(/\s+/g, '-').toLowerCase()
  const zip = new JSZip()

  try {
    const downloadableResources = resources
      .filter(filterResources)
      .map(mappedResources)

    const promises = downloadableResources.map(url => {
      return fetchSemaphore.lock(async () => {

        // We set redirect as manual to avoid following redirects
        // If the resource is too large or it redirects to a page,
        // we don't want to redirect

        const res = await fetch(url, { redirect: 'manual' })
        const contentType = res.headers.get('Content-Type')
        const responseStatus = res.status

        if (responseStatus !== 200 || contentType === 'text/html') return

        const blob = await res.blob()
        const fileName = res.headers.get('Content-Disposition')
          .split('filename=')[1]
          .split(';')[0]
          .replace(/"/g, '')
        zip.file(fileName, blob)
      })
    })

    await Promise.all(promises)

    const content = await zip.generateAsync({ type: 'blob' })
    const url = window.URL.createObjectURL(content)
    const link = document.createElement('a')
    link.href = url
    link.download = `${safeZipName}.zip`
    link.click()

  } catch (error) {
    console.error(`Error occured while downloading resources: ${error}`)
  }
}