import * as React from 'react'

import { default as ExpandLess } from '@material-ui/icons/ExpandLess'
import { default as ExpandMore } from '@material-ui/icons/ExpandMore'
import TreeItem from '@material-ui/lab/TreeItem'

import { Resolved } from '../../lib/contentful'
import { IResourceTree, ResourceTreeBranch } from '../../lib/contentful/generated'
import { isResolved } from '../../lib/contentful/utils'

import { SharePopover } from '../share-popover'
import { branchURL, renderLabel, renderPart } from './helpers'

export class Branch extends React.Component<ResourceTreeBranch | Resolved<ResourceTreeBranch>> {
  public render() {
    const branch = this.props
    const share = <SharePopover url={branchURL(branch.sys.id)}/>

    return <TreeItem
      nodeId={branch.sys.id}
      label={renderLabel(branch)}
      expandIcon={
        <>{share}<ExpandMore/></>
      }
      collapseIcon={
        <>{share}<ExpandLess/></>
      }
    >
      {this.renderBranches(branch)}
    </TreeItem>
  }

  private renderBranches(branch: Resolved<IResourceTree> | IResourceTree) {
    if (!isResolved(branch, 2)) { return <div className="loading-bar"/> }

    return branch.fields.branches.filter((b) => b).map((b) => renderPart(b))
  }
}
