import React from 'react'

export interface Props {
	pills: {
		text: string,
		href: string
	}[];
}

export const PillBoxMenu = (props: Props) => {
	const pills = props.pills.map((pill, index) => {
		let isExternal = true

		if(pill.href && (pill.href.startsWith('/') || window.location.hostname === new URL(pill.href, window.location.href).hostname)) {
			isExternal = false
		}

		return (
			<a href={pill.href} key={index} className="button primary pill py-2" target={isExternal ? '_blank' : ''} rel="noreferrer">{pill.text}</a>
		)
	})

	return (
		<div className='section-resource-landing__pillbox-menu'>
			{pills}
		</div>
	)
}
