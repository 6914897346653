import * as React from 'react'
import { IMenuButton, IResourceSet, IMenu, IResource, IPage } from '../../lib/contentful/generated'
import { ResourceSet } from './resource-set'
import { PillBoxMenu } from './pillbox-menu'
import { Resolved, isEntry } from '../../lib/contentful'
import { LessonSets } from './lesson-sets'
import { useState } from 'react'
import path from 'path'
import { ILessonMenu } from './lesson-menu'

export interface IProps {
  title: string,
  pills: {
    text: string,
    href: string
  }[],
  lessonSets: ILessonMenu[];
  resourceSet?: {
    resources: Resolved<IResource>[],
    title: string,
    url: string
  },
  searchPage?: IPage,
}

export function ResourceLanding({ title, pills, lessonSets, resourceSet, searchPage }: IProps) {
  const [ searchState, setSearchState ] = useState('')
  const onChange = event => setSearchState(event.target.value)

  let resources
  if (resourceSet) {
    resources = resourceSet.resources
  } else {
    resources = []
  }

  const onSubmit = (e) => {
    e.preventDefault()

    const queryUrl = new URL(window.location.href)
    queryUrl.pathname = searchPage.fields.slug
    queryUrl.searchParams.set('query', searchState)

    window.location.href = queryUrl.toString()
  }

  return (
    
    <div className='row section-resource-landing__menu-row justify-content-center'>
      <div className='col-12 col-md-8'>
        <h1 className='section-resource-landing__header'>{title}</h1>
        <PillBoxMenu pills={pills} />

        {searchPage && (
        <div className="section-resource-landing__search">
          <form onSubmit={onSubmit}>
            <input placeholder='Search resources and FAQs' type="search" value={searchState} onChange={onChange} />
          </form>
        </div>)}

        <LessonSets sets={lessonSets} />

        {resourceSet && (
        <ResourceSet title={resourceSet.title}
          base_url={resourceSet.url}
          resources={resources}
          limit={3} />
        )}
      </div>
    </div>
    
  )
}
