import React from 'react'
import WCC from 'wcc'
import * as I18n from 'i18n-js'
import aa from 'search-insights'

import connectGoogleMaps from '../../lib/connectors/googleMaps'

import * as LocationMap from '@watermarkchurch/location-map'


interface IProps {
  domainObject: {
    id: string;
    title: string;
    type: string;
  },
  indexName: string
  searchAsYouType?: boolean
  preview?: boolean
  userToken?: string
}

const ConnectedLocationMap = connectGoogleMaps(LocationMap.default) as any

export default (props: IProps) => {
  aa('init', {
    appId: WCC.WMR_CONSTANTS.ALGOLIA_APP_ID,
    apiKey: WCC.WMR_CONSTANTS.ALGOLIA_SEARCH_API_KEY,
    userToken: props.userToken
  })

  return <ConnectedLocationMap {...props}
    algoliaApiKey={WCC.WMR_CONSTANTS.ALGOLIA_SEARCH_API_KEY}
    algoliaAppId={WCC.WMR_CONSTANTS.ALGOLIA_APP_ID}
    translate={I18n.translate.bind(I18n)}
    sendAnalytics={!props.preview}
    aa={aa} />
}

