import * as React from 'react'
import { connectContentfulFetch, fetchEntry } from '../../lib/connectors/contentful'

import TreeView from '@material-ui/lab/TreeView'

import { AsyncErrorHandler } from '../../lib/async-error-handler'
import { ILink, isLink, Resolved } from '../../lib/contentful'
import {
  IResourceSetFields,
  IResourceTree,
  IResourceTreeFields,
  isResourceSet,
  isResourceTree,
  ResourceTreeBranch,
} from '../../lib/contentful/generated'
import { isResolved } from '../../lib/contentful/utils'

import { cache, renderPart } from './helpers'

interface IProps {
  tree: IResourceTree

  fetchEntry: typeof fetchEntry
}

interface IState {
  initiallyExpanded: string[]
  current: Resolved<ResourceTreeBranch>
  mounted: boolean

  wait?: boolean
  error?: any
}

export class ResourceTreeImpl extends React.Component<IProps, IState> {
  /**
   * The cache is for branches that we've already resolved down to a depth of 2.
   * If it's not resolved that far down, we need to fetch it.
   */
  private cache: { [id: string]: Resolved<ResourceTreeBranch> }
  private errorHandler: AsyncErrorHandler

  constructor(props: IProps, context) {
    super(props, context)

    this.errorHandler = new AsyncErrorHandler(this)
    this.onBranchClick = this.errorHandler.wrap(this, this.onBranchClick)
    this.supportLegacyHashOnMount = this.errorHandler.wrap(this, this.supportLegacyHashOnMount)

    this.state = {
      current: this.props.tree as Resolved<ResourceTreeBranch>,
      initiallyExpanded: [],
      mounted: !location.hash.length,
    }
    this.addToCache(props.tree)
  }

  public async componentDidMount() {
    if (this.state.mounted) { return }

    await this.supportLegacyHashOnMount()
    this.setState({ mounted: true })
  }

  public render() {
    const { tree } = this.props
    const { current, error, wait, initiallyExpanded, mounted } = this.state

    if (!mounted) {
      return <div className="my-5">
        <h3 className="h3 text-center">Loading Resources...</h3>
        <div className="loading-bar"/>
      </div>
    } else if (isResolved(tree)) {
      return <TreeView
        className={`resource-tree__tree-view ${wait ? 'wait' : ''}`}
        defaultExpanded={initiallyExpanded}
        onNodeToggle={this.onBranchClick}
      >
        {error && <h2 className="error">An unexpected error occurred!  Please try refreshing the page.</h2>}
        { tree.fields.branches.filter((b) => b).map((b) => renderPart(b)) }
      </TreeView>
    }

    return null
  }

  private async onBranchClick(evt: React.MouseEvent<HTMLDivElement>, ids: string[]) {
    const id = ids[0]

    this.setState({
      current: id ? cache.get(id) || (await this.fetch(id)) : null,
    })
  }

  /** Fetch a branch and ensure it's resolved down to depth of 2 */
  private async fetch(id: string, level = 2): Promise<Resolved<ResourceTreeBranch>> {
    const cached = cache.get(id)
    if (cached) { return cached }

    const fetched = await this.props.fetchEntry(id, level)
    if (isResourceSet(fetched) || isResourceTree(fetched)) {
      this.addToCache(fetched)
      return fetched as Resolved<ResourceTreeBranch>
    }
    throw new Error(`Unexpected content type returned! ${fetched.sys.contentType.sys.id}`)
  }

  private addToCache(tree: ResourceTreeBranch | ILink<'Entry'>) {
    if (!tree || isLink(tree)) { return }

    if (isResolved<IResourceSetFields | IResourceTreeFields>(tree, 2)) {
      cache.put(tree)

      if (isResourceTree(tree)) {
        const { branches } = (tree as IResourceTree).fields

        if (branches) { [...branches].forEach((b) => this.addToCache(b)) }
      }
    }
  }

  private async supportLegacyHashOnMount() {
    if (location.hash && location.hash.length > 0) {
      const hashId = location.hash.replace(/^\#/, '')
      const path = this.calcPathTo(hashId)
      const current = await this.fetch(hashId)
      if (current) {
        this.setState({
          current,
          initiallyExpanded: (await path) || [],
        })
      }

      // remove hash from URL after handling
      // history.replaceState(null, '', ' ')
    }
  }

  private async calcPathTo(hashId: string) {
    let path

    for (const br of this.props.tree.fields.branches) {
      path = await this.traverseNode(br as ResourceTreeBranch, hashId)

      if (path) { return path }
    }
  }

  private async traverseNode(node: ResourceTreeBranch, targetId: string) {
    // Our base case: return when we find the correct node
    if (node.sys.id == targetId) { return [targetId] }

    let result: string[] | null = null

    if (isResourceTree(node)) {
      // Fetch a resolved version of the current node
      node = await this.fetch(node.sys.id)

      if (!isResourceTree(node)) { return }

      // Traverse the current nodes branches
      for (const br of node.fields.branches) {
        result = await this.traverseNode(br as ResourceTreeBranch, targetId)

        // If the current node was in the path, return this node and the lower path
        if (result) { return [node.sys.id, ...result] }
      }
    }
  }
}

export const ResourceTree = connectContentfulFetch(ResourceTreeImpl)
