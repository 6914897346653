import * as React from 'react'
import AlgoliaSearch from '../algolia-search'
import Hits from './hits'
import SearchInput from './search-input'

interface IProps {
  indexName: string
  widgetName: string
}

export default class extends React.Component<IProps> {
  public render() {
    return (
      <AlgoliaSearch
        indexName={this.props.indexName}
        widgetName={this.props.widgetName}
      >
        <SearchInput />
        <Hits />
      </AlgoliaSearch >
    )
  }
}
