import * as React from 'react';
import { HiddenFacets } from '@watermarkchurch/react-instantsearch-components';
const SearchConfig = ({ domainObject, type }) => {
    const facets = {};
    facets[domainObject.type] = domainObject.title;
    if (type == 'virtual') {
        facets['types'] = 'virtual';
    }
    return React.createElement(HiddenFacets, { hiddenFacets: facets });
};
export default SearchConfig;
